import { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAcceptTeamInvitation } from '@travelity/api';

const AcceptTeamInvitation = () => {
    const navigate = useNavigate();
    const { key } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const { mutate: accept } = useAcceptTeamInvitation({
        onSuccess: () => {
            navigate('/events');
            enqueueSnackbar('Successfully joined a new team', {
                variant: 'success',
            });
        },
        onError: () => {
            navigate('/events');
            enqueueSnackbar('Failed to join a new team', {
                variant: 'error',
            });
        },
        retry: false,
    });

    useEffect(() => {
        if (key) {
            const t = setTimeout(() => accept({ key }), 1);
            return () => clearTimeout(t);
        }
        return () => null;
    }, [key]);

    return null;

    // return teamId && key ? (
    //     <ConfirmationDialog
    //         title={
    //             <Stack direction="row" gap={1} alignItems="center">
    //                 <Warning color="warning" />
    //                 Warning
    //             </Stack>
    //         }
    //         content="If the invitation you are accepting is for an Enterprise team, then all your current memberships will be discarded."
    //         confirmText="Confirm"
    //         open
    //         handleCancel={() => {
    //             navigate('/events');
    //         }}
    //         handleConfirm={() => {
    //             accept({ key });
    //         }}
    //     />
    // ) : null;
};

export default memo(AcceptTeamInvitation);
