import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import {
    useRemoveAttachmentCustomers,
    useUploadAttachmentCustomers,
} from '../../queries';
import { getCustomerDtoToCustomer } from './customer.converters';
import {
    ContentType,
    CreateCustomerReqDto,
    CreateCustomerResDto,
    CustomersService,
    GetCustomer1ResDto,
    type UpdateCustomerReqDto,
    UpdateCustomerResDto,
} from '../../requests';
import { CustomMutationOptions, CustomQueryOptions } from '../common.types';
import { Attachment, Customer } from './customer.types';

interface UseCustomersData {
    name?: string;
    email?: string;
    phoneNumber?: string;
    pageNumber?: number;
    pageSize?: number;
}

const useCustomerDebounceKey = 'useCustomerDebounceKey';
export const useDebouncedCustomers = (
    search: string,
    options?: CustomQueryOptions<
        ReturnType<typeof CustomersService.getCustomer>
    >
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        UseCustomersData | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: UseCustomersData = {};
            if (search.match(/^\+?[0-9 ]+$/)) filter.phoneNumber = search;
            else if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.length > 2) {
                filter.name = search;
            }
            setDebouncedParams(Object.keys(filter).length ? filter : undefined);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useCustomerDebounceKey, debouncedParams],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer(
                authorization,
                debouncedParams?.name,
                debouncedParams?.email,
                debouncedParams?.phoneNumber
            );
        },
        keepPreviousData: false,
        staleTime: 0,
        enabled: !!debouncedParams,
        ...options,
    });

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getCustomerDtoToCustomer)
                : undefined,
        [debouncedParams, data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useAddCustomer = (
    options?: CustomMutationOptions<
        {
            customer: CreateCustomerReqDto;
            attachments: Attachment[];
        },
        CreateCustomerResDto
    >
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: addAttachment } = useUploadAttachmentCustomers();

    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ customer, attachments }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const data = await CustomersService.createCustomer(
                authorization,
                customer
            );
            if (attachments.length > 0) {
                await Promise.allSettled(
                    attachments.map(attachment => {
                        return new Promise<UpdateCustomerResDto>(res => {
                            addAttachment(
                                {
                                    requestBody: {
                                        content_type:
                                            attachment.contentType as ContentType,
                                        name: attachment.name,
                                        // @ts-ignore
                                        content: attachment.content,
                                        // @ts-ignore
                                        width: attachment.width,
                                        // @ts-ignore
                                        height: attachment.height,
                                    },
                                    customerId: data.id,
                                },
                                {
                                    onSuccess: d => {
                                        // @ts-ignore
                                        res(d);
                                    },
                                    onError: () => {
                                        enqueueSnackbar(
                                            'Failed to upload file',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                    },
                                }
                            );
                        });
                    })
                );
            }
            return data;
        },
        { ...options }
    );
};

const useCustomerKey = 'useCustomerKey';

export const useUpdateCustomer = (
    customer?: Customer,
    options?: CustomMutationOptions<{
        id: string;
        customer: UpdateCustomerReqDto;
        attachments?: Attachment[];
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    const { mutateAsync: removeFile } = useRemoveAttachmentCustomers();

    return useMutation(
        async ({ id, customer: newCustomer, attachments = [] }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            const promises: Promise<UpdateCustomerResDto>[] = [
                CustomersService.updateCustomer(id, authorization, newCustomer),
            ];
            attachments?.forEach(attachment => {
                if (!attachment.uuid) {
                    promises.push(
                        CustomersService.uploadAttachmentCustomers(
                            id,
                            authorization,
                            {
                                // @ts-ignore
                                content: attachment.content,
                                // @ts-ignore
                                content_type: attachment.contentType,
                                name: attachment.name,
                                // @ts-ignore
                                width: attachment.width,
                                // @ts-ignore
                                height: attachment.height,
                            }
                        )
                    );
                }
            });

            customer?.attachments?.forEach(attachment => {
                const exists = attachments?.find(
                    b => b.uuid === attachment.uuid
                );
                if (!exists) {
                    promises.push(
                        removeFile({
                            customerId: id,
                            attachmentId: attachment.uuid as string,
                        })
                    );
                }
            });

            const [updateResult] = await Promise.allSettled(promises);

            let response: Partial<Omit<GetCustomer1ResDto, 'rbac'>>;
            if (updateResult.status === 'rejected') {
                response = await CustomersService.getCustomer1(
                    id,
                    authorization
                );
            } else {
                // @ts-ignore
                response = updateResult.value;
            }

            queryClient.setQueryData<GetCustomer1ResDto>(
                [useCustomerKey, id],
                prevCustomer =>
                    prevCustomer
                        ? {
                              ...prevCustomer,
                              ...response,
                          }
                        : undefined
            );

            return response;
        },
        options
    );
};

export const useCustomer = (
    id?: string,
    options: CustomQueryOptions<
        ReturnType<typeof CustomersService.getCustomer1>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...rest } = useQuery({
        queryKey: [useCustomerKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer1(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    const parsedData = useMemo(
        () => (data ? getCustomerDtoToCustomer(data) : undefined),
        [data]
    );

    return {
        data: parsedData,
        ...rest,
    };
};

export const useCreateCustomer = (
    options?: CustomMutationOptions<
        CreateCustomerReqDto,
        ReturnType<typeof CustomersService.createCustomer>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async requestBody => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.createCustomer(authorization, requestBody);
        },
        ...options,
    });
};
