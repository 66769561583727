import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

import { ReactComponent as Logo } from '../../assets/travelity-logo.svg';

function LogoSection() {
    return (
        <ButtonBase disableRipple component={Link} to="/">
            <Logo
                style={{
                    height: '62px',
                    width: '160px',
                }}
            />
        </ButtonBase>
    );
}

export default React.memo(LogoSection);
