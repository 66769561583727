/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetEventResBookingsItem0ProductCustomersDto } from './GetEventResBookingsItem0ProductCustomersDto';
import type { GetEventResBookingsItem0ProductOptionsDto } from './GetEventResBookingsItem0ProductOptionsDto';
import type { GetEventResBookingsItem0ProductPricingDto } from './GetEventResBookingsItem0ProductPricingDto';
import type { GetProductResConfigurationDto } from './GetProductResConfigurationDto';

export type GetEventResBookingsItem0ProductDto = {
    /**
     * type of the product
     */
    type: GetEventResBookingsItem0ProductDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Itemized summary of travelers' age information.
     */
    customers: GetEventResBookingsItem0ProductCustomersDto;
    /**
     * Booked Pricing option
     */
    pricing: GetEventResBookingsItem0ProductPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: GetEventResBookingsItem0ProductOptionsDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: GetProductResConfigurationDto;
};

export namespace GetEventResBookingsItem0ProductDto {

    /**
     * type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
