import { travelerToTravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.converters';
import { TravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.types';
import { addDays, addMinutes } from 'date-fns';
import {
    Booking,
    BookingPriceParams,
    BookingPriceSummary,
    BookingProductOption,
    BookingProductPricing,
    BookingRoute,
    BookingStatus,
    Transaction,
} from './booking.types';
import {
    type AgebandPricingData,
    type CreateBookingReqProductDto,
    CreateBookingResFinancialsDto,
    type FlatPricingData,
    type GetBookingsResItem0Dto,
    type GetBookingsResItem1Dto,
    type GetEventsResItem0BookingsItem0ProductOptionsItemDto,
    GetEventsResItem0BookingsItem0ProductPricingDto,
    GetEventsResItem0BookingsItem0Source0Dto,
    GetEventsResItem0BookingsItem0TransactionsItemDto,
    type GetProductsResItem0RouteDto,
    PeriodData,
    PersonNameData,
    UpdateCustomersBookingsReqItemDto,
    UpdateProductOptionsBookingsReqItemDto,
} from '../../requests';
import { convertNameDtoToFullName } from '../user/user.converters';
import {
    convertActivityDtoToActivity,
    convertDateToTimestamp,
    convertPeriodDataToPeriod,
    convertTimeOffsetToMinutes,
    convertTimestampToDate,
    getStatusChangeReason,
} from '../common.converters';
import { SourceType } from '../common.types';
import { getCustomerDtoToCustomer } from '../customer/customer.converters';
import { UseBookingsLazyData } from './booking.hooks';

export const convertBookingOptionToBookingOptionDto = (
    option: BookingProductOption
): Omit<UpdateProductOptionsBookingsReqItemDto, 'action'> => ({
    id: option.id,
    customers: {
        pax: option.pax,
    },
});

export const convertPricingDtoToPricing = (
    pricing: GetEventsResItem0BookingsItem0ProductPricingDto
): BookingProductPricing => ({
    id: pricing.id,
    type: pricing.type,
    currency: pricing.currency,
    paymentRequirements: pricing.payment_requirements,
    inclusions: pricing.inclusions,
    cost: pricing.cost?.original as FlatPricingData | AgebandPricingData,
    price: pricing.price.original as FlatPricingData | AgebandPricingData,
    discount: pricing.discount,
});

export const convertBookingOptionDtoToBookingOption = (
    option: GetEventsResItem0BookingsItem0ProductOptionsItemDto
): BookingProductOption => ({
    id: option.id,
    pax: option.customers.pax,
    pricing: option.pricing
        ? convertPricingDtoToPricing(option.pricing)
        : undefined,
    name: option.name,
    type: option.type,
});

type GetBookingsResItemDto = GetBookingsResItem0Dto | GetBookingsResItem1Dto;

export const convertTransactionDtoToTransaction = (
    transaction: GetEventsResItem0BookingsItem0TransactionsItemDto
): Transaction => ({
    id: transaction.id,
    operator: convertNameDtoToFullName(transaction.created.by.name),
    type: transaction.type,
    amount: transaction.amount.original as number,
    createdDate: new Date(transaction.created.at * 1000),
    method: transaction.method,
    discarded: transaction.discarded
        ? convertActivityDtoToActivity(transaction.discarded)
        : undefined,
});

export const bookingRouteDtoToRoute = (
    route: GetProductsResItem0RouteDto,
    date: PeriodData
): BookingRoute => {
    return {
        round_trip: route.round_trip,
        items: route.items.map(item => ({
            ...item,
            time: convertTimestampToDate(
                date.start +
                    convertTimeOffsetToMinutes(
                        route.round_trip && item.type === 'endpoint'
                            ? date.duration
                            : item.schedule.arrival_offset
                    ) *
                        60
            ),
        })),
    };
};

export const getBookingDtoToBooking = (
    booking: GetBookingsResItemDto
): Booking => {
    let sourceName: string;
    if (
        booking.source.type ===
        GetEventsResItem0BookingsItem0Source0Dto.type.DIRECT
    ) {
        sourceName = booking.source.name;
    } else {
        const name = booking.source.reference.name
            ? convertNameDtoToFullName(
                  booking.source.reference.name as PersonNameData
              )
            : undefined;
        sourceName =
            booking.source.reference.organization?.name ||
            name ||
            booking.source.reference.contact_details?.emails?.[0] ||
            '';
    }

    return {
        id: booking.id,
        status: booking.status,
        // TODO move to separate common converter
        createdAt: new Date(booking.created.at * 1000),
        createdBy: convertNameDtoToFullName(booking.created.by.name),
        reason:
            booking.status === BookingStatus.CANCELLED
                ? getStatusChangeReason(
                      booking.lifecycle,
                      BookingStatus.CANCELLED
                  )
                : undefined,
        lifecycle: booking.lifecycle?.map(convertActivityDtoToActivity) || [],
        notes:
            booking.notes?.items.map(v => ({ id: v.id, content: v.content })) ||
            [],
        orderId: booking.order.id,
        orderNumber: booking.order.number,
        eventId: booking.event?.id,
        date: convertPeriodDataToPeriod(booking.event?.date as PeriodData),
        source: {
            type: booking.source.type as unknown as SourceType,
            name: sourceName,
        },
        route: booking.event?.operations?.route
            ? bookingRouteDtoToRoute(
                  booking.event.operations.route,
                  booking.event?.date as PeriodData
              )
            : undefined,
        product: {
            id: booking.product.id,
            type: booking.product.type,
            name: booking.product.name,
            pricing: convertPricingDtoToPricing(booking.product.pricing),
            options:
                booking.product.options?.items.map(o =>
                    convertBookingOptionDtoToBookingOption(o)
                ) || [],
        },
        financials: {
            debt: booking.financials.debt.original as number,
            cost: (booking.financials.cost?.original || 0) as number,
            subtotal: booking.financials.subtotal.original as number,
            total: booking.financials.total.original as number,
            paid: (booking.transactions?.paid?.original || 0) as number,
            waived: (booking.transactions?.waived?.original || 0) as number,
            refunded: (booking.transactions?.refunded?.original || 0) as number,
        },
        participants: {
            customer: getCustomerDtoToCustomer(booking.order.customer),
            pax: booking.product.customers.pax,
            travelers:
                booking.product.customers.items.map(travelerToTravelerForm) ||
                [],
        },
        permissions: {
            canEdit: !!booking.access.permissions?.update,
            canDelete: !!booking.access.permissions?.delete,
        },
        transactions:
            booking.transactions?.items?.map(
                convertTransactionDtoToTransaction
            ) || [],
        shared: booking.product.configuration?.shared,
    };
};

export const bookingFiltersToRequestBodyConverter = (
    filters: Record<string, any>
): UseBookingsLazyData => ({
    startTs: filters?.dates?.startDate
        ? Math.round(filters.dates.startDate.getTime() / 1000)
        : undefined,
    endTs: filters?.dates?.endDate
        ? convertDateToTimestamp(
              addMinutes(addDays(filters.dates.endDate, 1), -1)
          )
        : undefined,
    types: filters.type ? [filters.type] : undefined,
    statuses: filters.statuses,
    order_number: filters.orderNumber || undefined,
    text_search: filters.searchText || undefined,
    ...((!!filters.createdAt || !!filters.createdBy) && {
        action: 'created',
        at_start: filters.createdAt?.startDate
            ? convertDateToTimestamp(filters.createdAt.startDate, true)
            : undefined,
        at_end: filters.createdAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.createdAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.createdBy || undefined,
    }),
    ...((!!filters.updatedAt || !!filters.updatedBy) && {
        action: 'last_updated',
        at_start: filters.updatedAt?.startDate
            ? convertDateToTimestamp(filters.updatedAt.startDate, true)
            : undefined,
        at_end: filters.updatedAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.updatedAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.updatedBy || undefined,
    }),
    ...((!!filters.deletedAt || !!filters.deletedBy) && {
        action: 'deleted',
        at_start: filters.deletedAt?.startDate
            ? convertDateToTimestamp(filters.deletedAt.startDate, true)
            : undefined,
        at_end: filters.deletedAt?.endDate
            ? convertDateToTimestamp(
                  addMinutes(addDays(filters.deletedAt.endDate, 1), -1),
                  true
              )
            : undefined,
        by: filters.deletedBy || undefined,
    }),
});

export const convertBookingTravelerToBookingTravelerDto = (
    traveler: TravelerForm
): Omit<UpdateCustomersBookingsReqItemDto, 'action'> => ({
    id: traveler.travelerId,
    pickup: traveler.pickUp
        ? {
              id: traveler.pickUp.id,
              type: traveler.pickUp.type,
              location: traveler.pickUp.name
                  ? {
                        name: traveler.pickUp.name,
                    }
                  : undefined,
              schedule: {
                  date: {
                      start: convertDateToTimestamp(traveler.pickUp.time, true),
                  },
              },
          }
        : undefined,
    plus_x: traveler.guestCount,
    reference: {
        id: traveler.customerId,
    },
});

export const convertBookingPriceParamsAndPriceResponseToBookingPriceSummary = (
    params: BookingPriceParams,
    price?: CreateBookingResFinancialsDto
): BookingPriceSummary => ({
    currency: params.currency,
    final: price ? (price.total.original as number) : 0,
    beforeDiscount: price ? (price.subtotal.original as number) : 0,
    discount: params.discount,
    pax: params.pax,
    price: params.price,
    options: params.options.map(o => ({
        name: o.name,
        type: o.type,
        pax: o.pax,
        price: o.price,
    })),
});

export const convertBookingPriceParamsToCreateBookingReqItemDto = (
    params: BookingPriceParams
): CreateBookingReqProductDto => ({
    id: params.productId,
    capacity_option_id: params.capacityId,
    customers: {
        items: params.customerId
            ? [
                  {
                      id: '',
                      customer_id: params.customerId,
                  },
              ]
            : [],
        pax: params.pax,
    },
    date: {
        start: convertDateToTimestamp(new Date(params.time)),
    },
    pricing: params.discount?.amount
        ? {
              discount: params.discount,
          }
        : undefined,
    options: params.options.length
        ? {
              items: params.options.map(o => ({
                  id: o.id,
                  customers: {
                      items: params.customerId
                          ? [
                                {
                                    id: '',
                                    customer_id: params.customerId,
                                },
                            ]
                          : [],
                      pax: o.pax,
                  },
              })),
          }
        : undefined,
});
