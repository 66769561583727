/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateTeamReqAccessGroupsItemReference0Dto = {
    /**
     * Type of the user group
     */
    type: CreateTeamReqAccessGroupsItemReference0Dto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Name of the user group
     */
    name?: string;
};

export namespace CreateTeamReqAccessGroupsItemReference0Dto {

    /**
     * Type of the user group
     */
    export enum type {
        INDIVIDUAL = 'individual',
    }


}
