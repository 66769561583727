import { Weekdays } from '@travelity/form';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import {
    Country,
    DayOfWeek,
    GetUserResAccountMembershipsItem0Dto,
    GetUserResAccountMembershipsItem1Dto,
    GetUserResAccountSettingsCalendarWorkweekDto,
    GetUserResDto,
    Nationality,
    PersonNameData,
    RegisterUsersReqDto,
    TimezoneName,
    type UpdateUserReqAccountSettingsCalendarDto,
    type UpdateUserReqAccountSettingsDto,
    UpdateUserReqDto,
} from '../../requests';
import { AccessObject, User } from './user.types';
import { currencies } from '../common.types';

export const subscriptionKeys = {
    free: process.env.REACT_APP_SUBSCRIPTION_KEY_FREE,
    professional: process.env.REACT_APP_SUBSCRIPTION_KEY_PROFESSIONAL,
    team: process.env.REACT_APP_SUBSCRIPTION_KEY_TEAM,
    enterprise: process.env.REACT_APP_SUBSCRIPTION_KEY_ENTERPRISE,
};

export const convertFullNameToNameDto = (fullName: string): PersonNameData => {
    const [first, ...rest] = fullName.split(' ');
    const last = rest.pop() || '';
    const middle = rest.join(' ') || undefined;

    return {
        first,
        middle,
        last,
    };
};

export const convertNameDtoToFullName = (name: PersonNameData): string => {
    const { first, middle, last } = name;

    return middle ? `${first} ${middle} ${last}` : `${first} ${last}`;
};

const convertDaysOfWeekToWeekDays = (daysOfWeek: DayOfWeek): Weekdays => {
    switch (daysOfWeek) {
        case DayOfWeek.MONDAY:
            return Weekdays.MONDAY;
        case DayOfWeek.TUESDAY:
            return Weekdays.TUESDAY;
        case DayOfWeek.WEDNESDAY:
            return Weekdays.WEDNESDAY;
        case DayOfWeek.THURSDAY:
            return Weekdays.THURSDAY;
        case DayOfWeek.FRIDAY:
            return Weekdays.FRIDAY;
        case DayOfWeek.SATURDAY:
            return Weekdays.SATURDAY;
        case DayOfWeek.SUNDAY:
        default:
            return Weekdays.SUNDAY;
    }
};

const dayOfWeek: Record<string, DayOfWeek> = {
    mon: DayOfWeek.MONDAY,
    tue: DayOfWeek.TUESDAY,
    wed: DayOfWeek.WEDNESDAY,
    thu: DayOfWeek.THURSDAY,
    fri: DayOfWeek.FRIDAY,
    sat: DayOfWeek.SATURDAY,
    sun: DayOfWeek.SUNDAY,
};

const workWeekDtoToWorkdayHours = (
    workWeek?: GetUserResAccountSettingsCalendarWorkweekDto
) => {
    const workdayHours: Record<Weekdays, { start: number; end: number }[]> = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
    };

    Object.entries(workWeek || {}).forEach(([key, value]) => {
        if (value?.work_hours) {
            workdayHours[convertDaysOfWeekToWeekDays(key as DayOfWeek)] =
                value.work_hours.map(({ start, end }) => ({
                    start: start.hours * 60 + (start?.minutes || 0),
                    end: end.hours * 60 + (end?.minutes || 0),
                }));
        }
    });

    return workdayHours;
};

const convertRoleItemsToRoleAccess = (
    items: (
        | GetUserResAccountMembershipsItem0Dto
        | GetUserResAccountMembershipsItem1Dto
    )[]
) => {
    const roleAccess: Record<string, AccessObject> = {};

    items.forEach(item => {
        Object.entries(item.role.access).forEach(([key, value]) => {
            if (value) {
                roleAccess[key] = {
                    read: value.permissions.read || roleAccess[key]?.read,
                    create: value.permissions.create || roleAccess[key]?.create,
                };
            }
        });
    });

    return roleAccess;
};

const convertRoleItemToRoleAccess = (
    item:
        | GetUserResAccountMembershipsItem0Dto
        | GetUserResAccountMembershipsItem1Dto
) => {
    const roleAccess: Record<string, AccessObject> = {};

    Object.entries(item.role.access).forEach(([key, value]) => {
        if (value) {
            roleAccess[key] = {
                read: value.permissions.read || roleAccess[key]?.read,
                create: value.permissions.create || roleAccess[key]?.create,
            };
        }
    });

    return roleAccess;
};

export const userDtoToUser = (userDto: GetUserResDto): User => ({
    id: userDto.id,
    fullName: convertNameDtoToFullName(userDto.profile.name),
    languages: userDto.profile.languages,
    occupation: userDto.profile.occupation,
    currency: userDto.account.settings.financial?.currencies.find(c => c.base)
        ?.abbr,
    currencies: userDto.account.settings.financial?.currencies
        .filter(c => !c.base)
        .map(c => ({
            name: c.abbr,
            rate: c.rate as number,
        })),
    timeZone: userDto.account.settings.calendar.timezone?.name,
    weekdays: Object.keys(
        userDto.account.settings.calendar.workweek || {}
    )?.map(day_of_week =>
        convertDaysOfWeekToWeekDays(day_of_week as DayOfWeek)
    ),
    weekdayHours: workWeekDtoToWorkdayHours(
        userDto.account.settings.calendar.workweek
    ),
    email: userDto.profile.contact_details?.emails?.[0],
    emails: userDto.profile.contact_details?.emails?.map(value => ({ value })),
    numbers: userDto.profile.contact_details?.phone_numbers?.map(
        ({ number, calling_code }) => {
            return { value: `${calling_code} ${number}` };
        }
    ),
    birthDate: userDto.profile.birth_details?.date
        ? format(
              new Date(userDto.profile.birth_details.date * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    birthPlace: userDto.profile.birth_details?.place?.name,
    nationality: userDto.profile.birth_details?.nationality,
    passportN: userDto.profile.passport?.number,
    issuedBy: userDto.profile.passport?.authority,
    issuedAt: userDto.profile.passport?.issued_at
        ? format(
              new Date(userDto.profile.passport.issued_at * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    expiresAt: userDto.profile.passport?.expires_at
        ? format(
              new Date(userDto.profile.passport.expires_at * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    citizenship: userDto.profile.passport?.citizenship,
    avatar: userDto.profile.avatar?.url,
    subscription: userDto.subscription,
    visibleResources: userDto.subscription.resources || [],
    // roleAccess: convertRoleItemsToRoleAccess(userDto.account.memberships.items),
    roleAccess: convertRoleItemToRoleAccess(
        userDto.account.workspaces.current.membership
    ),
    workspaces: {
        selected: {
            id: userDto.account.workspaces.current.membership.group.id,
            name: userDto.account.workspaces.current.name,
        },
        list: userDto.account.workspaces.items.map(({ membership, name }) => ({
            id: membership.group.id,
            name,
        })),
    },
});

export const userWeekdaysToWorkweek = (
    user: Partial<User>
): GetUserResAccountSettingsCalendarWorkweekDto | undefined =>
    user.weekdayHours
        ? Object.keys(user.weekdayHours).reduce(
              (obj, key) => ({
                  ...obj,
                  [dayOfWeek[key] as DayOfWeek]: {
                      work_hours:
                          user.weekdayHours?.[key as Weekdays]?.map(
                              ({ start, end }) => ({
                                  start: {
                                      hours: Math.floor(start / 60),
                                      minutes: start % 60,
                                  },
                                  end: {
                                      hours: Math.floor(end / 60),
                                      minutes: end % 60,
                                  },
                              })
                          ) || [],
                  },
              }),
              {}
          )
        : undefined;

export const stringToDate = (str: string): Date => {
    const [d, m, y] = str.split('.');
    if (!d || !m || !y) return new Date();

    return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
};

export const dateToUnix = (date: Date | string) =>
    typeof date === 'string'
        ? Math.round(stringToDate(date).getTime() / 1000)
        : Math.round(date.getTime() / 1000);

export const userToUserAccountSettingsDto = (
    user: Partial<User>
): Partial<UpdateUserReqAccountSettingsDto> => {
    const accountSettings: Partial<UpdateUserReqAccountSettingsDto> = {};
    const calendar: UpdateUserReqAccountSettingsCalendarDto = {};

    if (user.weekdayHours) {
        calendar.workweek = userWeekdaysToWorkweek(user);
    }

    if (user.timeZone) {
        calendar.timezone = {
            name: user.timeZone as TimezoneName,
            offset: 0,
        };
    }

    if (user.currency) {
        accountSettings.financial = {
            currencies: [
                {
                    name:
                        currencies.find(({ abbr }) => abbr === user.currency)
                            ?.name || '',
                    abbr: user.currency,
                    base: true,
                },
            ],
        };
    }

    if (!isEmpty(calendar)) {
        accountSettings.calendar = calendar;
    }
    return accountSettings;
};

export const currencyAbbrToCurrencyName = (currency: string) => {
    return currencies.find(({ abbr }) => abbr === currency)?.name || '';
};

export const userDetailsToUserDetailsDto = (
    user: Partial<User>
): UpdateUserReqDto | RegisterUsersReqDto => ({
    account: {
        settings: userToUserAccountSettingsDto(user),
    },
    profile: {
        occupation: user.occupation,
        languages: user.languages,
        name: user.fullName
            ? convertFullNameToNameDto(user.fullName)
            : undefined,
        contact_details:
            user.emails || user.numbers
                ? {
                      emails: user.emails?.map(({ value }) => value),
                      phone_numbers: user.numbers
                          ?.map(({ value }) => value)
                          .map(number => ({
                              calling_code: parseInt(number.split(' ')[0], 10),
                              number: parseInt(number.split(' ')[1], 10),
                          })),
                  }
                : undefined,
        birth_details: user.birthDate
            ? {
                  nationality:
                      (user.nationality?.toLowerCase() as Nationality) ||
                      undefined,
                  date: dateToUnix(user.birthDate),
                  place: user.birthPlace
                      ? {
                            name: user.birthPlace,
                        }
                      : undefined,
              }
            : undefined,
        passport: user.passportN
            ? {
                  number: user.passportN,
                  citizenship:
                      (user.citizenship?.toLowerCase() as Country) || undefined,
                  authority: user.issuedBy,
                  issued_at: user.issuedAt
                      ? dateToUnix(user.issuedAt)
                      : undefined,
                  expires_at: user.expiresAt
                      ? dateToUnix(user.expiresAt)
                      : undefined,
              }
            : undefined,
    },
});

export const userWorkweekToDetailsDto = (
    user: Partial<User>
): UpdateUserReqDto | RegisterUsersReqDto => ({
    account: {
        settings: userToUserAccountSettingsDto(user),
    },
});
