/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateTeamResAccessDto } from './CreateTeamResAccessDto';
import type { CreateTeamResAccountDto } from './CreateTeamResAccountDto';
import type { CreateTeamResMembersDto } from './CreateTeamResMembersDto';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';

export type CreateTeamResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateTeamResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Name of the team
     */
    name: string;
    /**
     * Name of the team
     */
    description?: string;
    /**
     * Reference of the team lead
     */
    members: CreateTeamResMembersDto;
    /**
     * Access to the shared product
     */
    access: CreateTeamResAccessDto;
    /**
     * Account details of the user
     */
    account: CreateTeamResAccountDto;
};

export namespace CreateTeamResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        TEAM = 'team',
    }


}
