/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamResMembersItemReferenceDto } from './GetTeamResMembersItemReferenceDto';

export type GetEventResBookingsItem0Source1Dto = {
    /**
     * type of the source
     */
    type: GetEventResBookingsItem0Source1Dto.type;
    /**
     * List of customers
     */
    reference: GetTeamResMembersItemReferenceDto;
};

export namespace GetEventResBookingsItem0Source1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
