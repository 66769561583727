/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateMembersTeamsResAccessDto } from './UpdateMembersTeamsResAccessDto';
import type { UpdateMembersTeamsResAccountDto } from './UpdateMembersTeamsResAccountDto';
import type { UpdateMembersTeamsResMembersDto } from './UpdateMembersTeamsResMembersDto';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';

export type UpdateMembersTeamsResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateMembersTeamsResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * Name of the team
     */
    name?: string;
    /**
     * Name of the team
     */
    description?: string;
    /**
     * Reference of the team lead
     */
    members?: UpdateMembersTeamsResMembersDto;
    /**
     * Access to the shared product
     */
    access?: UpdateMembersTeamsResAccessDto;
    /**
     * Account details of the user
     */
    account?: UpdateMembersTeamsResAccountDto;
};

export namespace UpdateMembersTeamsResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        TEAM = 'team',
    }


}
