/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatePeriodData } from './DatePeriodData';
import type { GetSchedulesResItemAccessDto } from './GetSchedulesResItemAccessDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { MonthlyRecurrenceData } from './MonthlyRecurrenceData';
import type { ScheduleType } from './ScheduleType';
import type { TimeOffset } from './TimeOffset';
import type { TimezoneData } from './TimezoneData';
import type { WeeklyRecurrenceData } from './WeeklyRecurrenceData';
import type { YearlyRecurrenceData } from './YearlyRecurrenceData';

export type GetSchedulesResItemDto = {
    /**
     * Type of the resource
     */
    resource_type: GetSchedulesResItemDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Name of the capacity option. Is used only for identification
     */
    type: ScheduleType;
    /**
     * Name of the current schedule segment
     */
    name: string;
    /**
     * Name of the timezone
     */
    timezone: TimezoneData;
    /**
     * List of time slots when the event is happening
     */
    start_times?: Array<TimeOffset>;
    /**
     * List of time slots when the event is happening
     */
    any_time?: boolean;
    /**
     * indicates whether the schedule is recurring or not
     */
    recurrence?: (WeeklyRecurrenceData | MonthlyRecurrenceData | YearlyRecurrenceData);
    /**
     * Dates of the schedule
     */
    dates: Array<DatePeriodData>;
    /**
     * Access to the shared product
     */
    access: GetSchedulesResItemAccessDto;
};

export namespace GetSchedulesResItemDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        SCHEDULE = 'schedule',
    }


}
