import React from 'react';
import { createPortal } from 'react-dom';
import {
    Box,
    Divider,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import toolbarIconsList, { IconType } from './toolbarIconsList';
import useOnClickListener from './useOnClickListener';
import FloatingLinkEditor from './floating-link-editor';
import _ from 'lodash';

const pluginsGrouped = Object.values(_.groupBy(toolbarIconsList, 'group'));
const LexicalEditorTopBar = () => {
    const { onClick, selectedEventTypes, blockType, isLink, format, editor } =
        useOnClickListener();

    const isIconSelected = (plugin: IconType) =>
        selectedEventTypes.includes(plugin.event) ||
        blockType.includes(plugin.event) ||
        format === plugin.event;

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ background: 'white', mb: 1, ml: 2 }}
        >
            {pluginsGrouped.map((plugins, i) => (
                <React.Fragment key={plugins[0]?.group}>
                    {i !== 0 && (
                        <Divider
                            flexItem
                            orientation="vertical"
                            sx={{ mx: 0.5, my: 1 }}
                        />
                    )}
                    <ToggleButtonGroup
                        value={[...selectedEventTypes, blockType, format]}
                        size="small"
                    >
                        {plugins.map((plugin, i) => (
                            <ToggleButton
                                key={plugin.event}
                                onClick={() => onClick(plugin.event)}
                                value={plugin.event}
                                aria-label="left aligned"
                                sx={{
                                    py: plugin.text ? 0.5 : 0.75,
                                    px: 0.75,
                                    margin: 0.25,
                                    height: '32px',
                                    width: '32px',
                                    transition: 'opacity 0.3s',
                                    '&&&': {
                                        border: 0,
                                        borderRadius: '4px',
                                    },
                                    '&&&.Mui-disabled': {
                                        border: 0,
                                    },
                                }}
                                size="small"
                            >
                                {plugin.text ? (
                                    <Box
                                        sx={{
                                            px: 0.5,
                                            fontSize: '14px',
                                            color: isIconSelected(plugin)
                                                ? '#FFF'
                                                : 'secondary.main',
                                        }}
                                    >
                                        {plugin.text}
                                    </Box>
                                ) : (
                                    <plugin.Icon
                                        fontSize="small"
                                        color={
                                            isIconSelected(plugin)
                                                ? '#FFF'
                                                : 'secondary'
                                        }
                                    />
                                )}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </React.Fragment>
            ))}

            {isLink &&
                createPortal(
                    <FloatingLinkEditor editor={editor} />,
                    document.body
                )}
        </Stack>
    );
};

export default LexicalEditorTopBar;
