import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { $createParagraphNode, $getRoot } from 'lexical';

type Props = { initialContent?: string };

export const LoadInitialContent = ({ initialContent }: Props) => {
    const [editor] = useLexicalComposerContext();

    React.useEffect(() => {
        if (!initialContent) {
            return;
        }
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialContent, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            nodes.forEach((n, i) => {
                if (
                    n.getTextContent() === '' &&
                    (i === 0 || i === nodes.length - 1)
                )
                    return;
                const existingChild = $getRoot().getChildAtIndex(i);
                if (existingChild) {
                    existingChild.replace(n);
                } else {
                    $getRoot().append(n);
                }
            });
        });
    }, []);
    return null;
};
