/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateProductResAccessDto } from './CreateProductResAccessDto';
import type { CreateProductResCapacityDto } from './CreateProductResCapacityDto';
import type { CreateProductResConfigurationDto } from './CreateProductResConfigurationDto';
import type { CreateProductResOptionsDto } from './CreateProductResOptionsDto';
import type { CreateProductResPricingDto } from './CreateProductResPricingDto';
import type { CreateProductResRouteDto } from './CreateProductResRouteDto';
import type { CreateProductResScheduleDto } from './CreateProductResScheduleDto';
import type { Language } from './Language';
import type { ProductTag } from './ProductTag';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';

export type CreateProductResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateProductResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Detailed textual description of the product.
     */
    description: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type: CreateProductResDto.type;
    /**
     * Indicates whether the product is active or not
     */
    active: boolean;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration: CreateProductResConfigurationDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    capacity: CreateProductResCapacityDto;
    /**
     * Defines the recurrent and non-recurrent items of the schedule
     */
    schedule: CreateProductResScheduleDto;
    /**
     * Financial information of the product.
     */
    pricing?: CreateProductResPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: CreateProductResOptionsDto;
    /**
     * Access to the shared product
     */
    access: CreateProductResAccessDto;
    /**
     * Route of the tour/transfer
     */
    route: CreateProductResRouteDto;
    /**
     * Languages in which the product/service is provided
     */
    languages: Array<Language>;
};

export namespace CreateProductResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        PRODUCT = 'product',
    }

    /**
     * Type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
