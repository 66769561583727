/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemAccessDto } from './GetTeamsResItemAccessDto';
import type { GetTeamsResItemAccountDto } from './GetTeamsResItemAccountDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { GetTeamsResItemMembersDto } from './GetTeamsResItemMembersDto';
import type { TeamInsightData } from './TeamInsightData';

export type GetTeamsResItemDto = {
    /**
     * Type of the resource
     */
    resource_type: GetTeamsResItemDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Name of the team
     */
    name: string;
    /**
     * Name of the team
     */
    description?: string;
    /**
     * Reference of the team lead
     */
    members: GetTeamsResItemMembersDto;
    /**
     * summary of shared products
     */
    insight?: TeamInsightData;
    /**
     * Access to the shared product
     */
    access: GetTeamsResItemAccessDto;
    /**
     * Account details of the user
     */
    account: GetTeamsResItemAccountDto;
};

export namespace GetTeamsResItemDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        TEAM = 'team',
    }


}
