import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { ProductCapacityItem, ProductScheduleItem } from '@travelity/api';
import { KeyboardArrowDown, NavigateNext } from '@mui/icons-material';
import { FinancingCardContent } from '../../../product-financing-form/components/financing-card';
import { FinancingCardHeader } from '../../../product-financing-form/components/financing-card-header';

export interface PricingListItemProps {
    item: Record<string, any>;
    editing: boolean;
    prefix: string;
    index: number;
    remove: (index: number) => void;
    usedDependencies: { capacity?: string; schedule?: string }[];
    capacities: ProductCapacityItem[];
    schedules: ProductScheduleItem[];
}

export const PricingListItem: React.FC<PricingListItemProps> = props => {
    const {
        item,
        editing,
        prefix,
        index,
        remove,
        capacities,
        schedules,
        usedDependencies,
    } = props;
    const [open, setOpen] = React.useState(!!item.new);

    const onRemove = useCallback(() => {
        remove(index);
    }, [remove, index]);

    return (
        <Stack
            sx={{
                bgcolor: '#F7F7F7',
                borderRadius: '12px',
                ...(open && {
                    boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                    border: '1px solid #55B5CF',
                    overflow: 'hidden',
                }),
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ height: '36px', px: 2 }}
            >
                <Box
                    onClick={() => setOpen(!open)}
                    sx={{
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        bgcolor: open ? '#55B5CF' : '#C6CAD3',
                        color: '#FFF',
                    }}
                >
                    {open ? (
                        <KeyboardArrowDown fontSize="small" />
                    ) : (
                        <NavigateNext fontSize="small" />
                    )}
                </Box>
                <FinancingCardHeader
                    item={item}
                    editing={editing}
                    usedDependencies={usedDependencies}
                    capacities={capacities}
                    schedules={schedules}
                    prefix={prefix}
                />
            </Stack>
            {open && (
                <FinancingCardContent
                    item={item}
                    editing={editing}
                    sx={{ bgcolor: '#FFF' }}
                    nested
                    onRemove={onRemove}
                    prefix={prefix}
                />
            )}
        </Stack>
    );
};
