/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemMembersItemReferenceDto } from './GetTeamsResItemMembersItemReferenceDto';

export type GetEventsResItem0BookingsItem0Source1Dto = {
    /**
     * type of the source
     */
    type: GetEventsResItem0BookingsItem0Source1Dto.type;
    /**
     * List of customers
     */
    reference: GetTeamsResItemMembersItemReferenceDto;
};

export namespace GetEventsResItem0BookingsItem0Source1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
