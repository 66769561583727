import React, { useState } from 'react';
import { WorkspaceItem } from '@travelity/api';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, List } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { LoadingOverlay } from '@travelity/ui';
import WorkspaceItemMinimal from './components/workspace-item-minimal';

export interface SwitchWorkspaceModalProps {
    list: WorkspaceItem[];
    selected: WorkspaceItem;
    isSwitching?: boolean;
    onSwitch: (workspaceId: string) => void;
    onClose: () => void;
}

export const SwitchWorkspaceModal: React.FC<
    SwitchWorkspaceModalProps
> = props => {
    const { list, selected: initial, onSwitch, onClose, isSwitching } = props;
    const [selected, setSelected] = useState(initial);

    return (
        <Dialog
            open
            onClose={onClose}
            disableRestoreFocus
            sx={{
                '& .MuiDialog-container': {
                    // justifyContent: 'end',
                    // alignItems: 'end',
                },
                '& .MuiDialog-paper': {
                    backgroundColor: '#FFF',
                    boxShadow: '0px 0px 12px 0px rgba(178, 185, 205, 0.50)',
                },
            }}
            PaperProps={{
                sx: {
                    // width: '600px',
                    // height: '400px',
                    m: 2,
                },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{ fontSize: '16px', fontWeight: 600 }}
                id="alert-dialog-title"
            >
                Switch Workspace
            </DialogTitle>
            <DialogContent sx={{ pb: 0, overflow: 'hidden' }}>
                <List>
                    {list.map(workspace => (
                        <WorkspaceItemMinimal
                            key={workspace.id}
                            workspace={workspace}
                            selected={workspace.id === selected.id}
                            onSelect={setSelected}
                        />
                    ))}
                </List>
                {isSwitching && <LoadingOverlay />}
            </DialogContent>
            <DialogActions sx={{ py: 2, px: 3 }}>
                <Button
                    variant="contained"
                    color="neutral"
                    onClick={onClose}
                    disabled={isSwitching}
                >
                    Discard
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSwitch(selected.id)}
                    disabled={isSwitching || selected.id === initial.id}
                >
                    Switch
                </Button>
            </DialogActions>
        </Dialog>
    );
};
