/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AgebandCapacityData } from './AgebandCapacityData';
import type { CapacityType } from './CapacityType';
import type { FlatCapacityData } from './FlatCapacityData';
import type { GetCapacityResAccessDto } from './GetCapacityResAccessDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';

export type GetCapacityResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetCapacityResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Name of the capacity option. Is used only for identification
     */
    type: CapacityType;
    /**
     * Name of the capacity option. Is used only for identification
     */
    name: string;
    /**
     * Type of the capacity of the product
     */
    limit: (FlatCapacityData | AgebandCapacityData);
    /**
     * Access to the shared product
     */
    access: GetCapacityResAccessDto;
};

export namespace GetCapacityResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CAPACITY = 'capacity',
    }


}
