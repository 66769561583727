/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetEventsResItem0BookingsItem0ProductOptionsDto } from './GetEventsResItem0BookingsItem0ProductOptionsDto';
import type { GetEventsResItem0BookingsItem0ProductPricingDto } from './GetEventsResItem0BookingsItem0ProductPricingDto';
import type { GetEventsResItem0BookingsItem1ProductCustomersDto } from './GetEventsResItem0BookingsItem1ProductCustomersDto';
import type { GetProductsResItem0ConfigurationDto } from './GetProductsResItem0ConfigurationDto';

export type GetEventsResItem0BookingsItem1ProductDto = {
    /**
     * type of the product
     */
    type: GetEventsResItem0BookingsItem1ProductDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Itemized summary of travelers' age information.
     */
    customers: GetEventsResItem0BookingsItem1ProductCustomersDto;
    /**
     * Booked Pricing option
     */
    pricing: GetEventsResItem0BookingsItem0ProductPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: GetEventsResItem0BookingsItem0ProductOptionsDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: GetProductsResItem0ConfigurationDto;
};

export namespace GetEventsResItem0BookingsItem1ProductDto {

    /**
     * type of the product
     */
    export enum type {
        TRANSFER = 'transfer',
    }


}
