import React from 'react';
import { Box, Chip, ChipProps, Stack } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export enum StatusTagStatuses {
    ERROR,
    NEUTRAL,
    SUCCESS,
    WARNING,
}

interface StatusTagColors {
    bg: string;
    icon: string;
    text: string;
}

const colors: Record<StatusTagStatuses, StatusTagColors> = {
    [StatusTagStatuses.ERROR]: {
        bg: '#FBD7DB',
        icon: '#EF5E6F',
        text: '#D84F5F',
    },
    [StatusTagStatuses.NEUTRAL]: {
        bg: '#F4F6FA',
        icon: '#B2B9CD',
        text: '#2B395B',
    },
    [StatusTagStatuses.SUCCESS]: {
        bg: '#CDEFDB',
        icon: '#37C16E',
        text: '#2CAC60',
    },
    [StatusTagStatuses.WARNING]: {
        bg: '#FDF0B1',
        icon: '#F8D627',
        text: '#AD9726',
    },
};

export interface StatusTagProps extends Partial<ChipProps> {
    status: StatusTagStatuses;
    label?: string;
    small?: boolean;
    value?: string;
}

export const StatusTag: React.FC<StatusTagProps> = props => {
    const { label, small, status, value, ...rest } = props;
    const { bg, icon, text } = colors[status];

    return (
        <Chip
            {...rest}
            sx={{
                ...(rest.sx || {}),
                '& .MuiChip-label': {
                    whiteSpace: 'nowrap',
                    mt: '-4px',
                },
            }}
            variant="outlined"
            color="primary"
            size="small"
            label={
                <Stack direction="row" gap={0.5} alignItems="center">
                    <FiberManualRecordIcon
                        sx={{ fontSize: '14px', color: icon, mt: 0.5 }}
                    />
                    <>
                        {label && (
                            <Box
                                sx={{
                                    px: 0.5,
                                    fontWeight:
                                        status === StatusTagStatuses.WARNING
                                            ? 600
                                            : 400,
                                    color: icon,
                                    mt: 0.5,
                                }}
                                component="span"
                            >
                                {label}
                            </Box>
                        )}
                        <Box
                            component="span"
                            sx={{
                                background: bg,
                                color: text,
                                lineHeight: '14px',
                                fontSize: '14px',
                                fontWeight: 400,
                                display: 'inline-block',
                                textAlign: 'center',
                                borderRadius: '6px',
                                minWidth: '25px',
                                height: '22px',
                                py: 0.5,
                                px: 1,
                                ml: 0.5,
                                mt: 0.5,
                                ...(small ? { py: '2px', height: '18px' } : {}),
                            }}
                        >
                            {value}
                        </Box>
                    </>
                </Stack>
            }
        />
    );
};
