/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BirthDetailsData } from './BirthDetailsData';
import type { GetCustomer1ResAccessDto } from './GetCustomer1ResAccessDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { GetUserResProfileAttachmentsItemDto } from './GetUserResProfileAttachmentsItemDto';
import type { GetUserResProfileContactDetailsDto } from './GetUserResProfileContactDetailsDto';
import type { GetUserResProfileOrganizationDto } from './GetUserResProfileOrganizationDto';
import type { Language } from './Language';
import type { Passport } from './Passport';
import type { PersonNameData } from './PersonNameData';

export type GetCustomer1ResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetCustomer1ResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details: GetUserResProfileContactDetailsDto;
    /**
     * List of paths in the storage where the passport scans/uploads are.
     */
    attachments?: Array<GetUserResProfileAttachmentsItemDto>;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * Birth details of the person.
     */
    birth_details?: BirthDetailsData;
    /**
     * Passport details of the person.
     */
    passport?: Passport;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: GetUserResProfileOrganizationDto;
    /**
     * Access to the shared product
     */
    access: GetCustomer1ResAccessDto;
};

export namespace GetCustomer1ResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CUSTOMER = 'customer',
    }


}
