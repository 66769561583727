import React, { useCallback, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, TextField } from '@travelity/form';
import { Box, FormLabel, Stack } from '@mui/material';
import { PrintData } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from '@travelity/ui';
import { RichTextEditor } from '@travelity/form/src/controls/rich-text-editor/rich-text-editor';
import { useLocalStorage } from '../../../hooks';

const formId = 'print-modal';

export interface PrintDataModalProps {
    handleCancel: () => void;
    handleConfirm: (v: PrintData) => void;
    open: boolean;
}

const PrintDataModal: React.FC<PrintDataModalProps> = ({
    handleCancel,
    open,
    handleConfirm,
}) => {
    const [value, setValue] = useLocalStorage('COMPANY_DETAILS_FOR_PDF', {});
    const convertedData = useMemo(() => {
        return {
            ...value,
            appendix: value.appendix
                ? {
                      content: value.appendix,
                  }
                : undefined,
        };
    }, [value]);

    const { Form, trigger, reset } = useForm<PrintData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        onSubmit: useCallback(
            (data: PrintData) => {
                setValue({ ...data, appendix: data.appendix?.content });
                handleConfirm({ ...data, appendix: data.appendix?.content });
            },
            [handleConfirm]
        ),
        defaultValues: convertedData,
    });

    useEffect(() => {
        reset(convertedData);
        if (open) trigger();
    }, [open, reset]);

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff' } }}
        >
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Box
                            sx={{
                                color: '#C0C4CE',
                                fontSize: '21px',
                                fontWeight: 600,
                            }}
                        >
                            Fill company details for report
                        </Box>

                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '520px' }}
                        >
                            <TextField
                                name="companyName"
                                label="Company name"
                                placeholder="Type Company name"
                            />
                            <TextField
                                name="website"
                                label="Website"
                                placeholder="Type Website"
                            />
                            <TextField
                                name="email"
                                label="Email"
                                placeholder="Type Email"
                            />
                            <TextField
                                name="phone"
                                label="Phone Number"
                                placeholder="Type Phone Number"
                            />
                            <TextField
                                name="address"
                                label="Address"
                                placeholder="Type Address"
                            />
                            <FormLabel>Appendix</FormLabel>
                            <RichTextEditor name="appendix" />
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Cancel
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    sx={{
                        py: '10px',
                    }}
                >
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PrintDataModal;
