/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetUserResAccountMembershipsItem1GroupDto = {
    /**
     * Type of the user group
     */
    type: GetUserResAccountMembershipsItem1GroupDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Name of the user group
     */
    name?: string;
};

export namespace GetUserResAccountMembershipsItem1GroupDto {

    /**
     * Type of the user group
     */
    export enum type {
        TEAM = 'team',
    }


}
