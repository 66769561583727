/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateMembersTeamsResMembersItemReferenceDto } from './UpdateMembersTeamsResMembersItemReferenceDto';

export type CancelEventsResBookingsItem0Source1Dto = {
    /**
     * type of the source
     */
    type: CancelEventsResBookingsItem0Source1Dto.type;
    /**
     * List of customers
     */
    reference: UpdateMembersTeamsResMembersItemReferenceDto;
};

export namespace CancelEventsResBookingsItem0Source1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
