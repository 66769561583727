/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BirthDetailsData } from './BirthDetailsData';
import type { CreateCustomerResAccessDto } from './CreateCustomerResAccessDto';
import type { Language } from './Language';
import type { Passport } from './Passport';
import type { PersonNameData } from './PersonNameData';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';
import type { RegisterUsersResProfileContactDetailsDto } from './RegisterUsersResProfileContactDetailsDto';
import type { RegisterUsersResProfileOrganizationDto } from './RegisterUsersResProfileOrganizationDto';

export type CreateCustomerResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateCustomerResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details: RegisterUsersResProfileContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * Birth details of the person.
     */
    birth_details?: BirthDetailsData;
    /**
     * Passport details of the person.
     */
    passport?: Passport;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: RegisterUsersResProfileOrganizationDto;
    /**
     * Access to the shared product
     */
    access: CreateCustomerResAccessDto;
};

export namespace CreateCustomerResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CUSTOMER = 'customer',
    }


}
