import React, { useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { WorkspaceItem } from '@travelity/api';
import { Card } from '@travelity/ui';

export interface WorkspaceItemMinimalProps {
    workspace: WorkspaceItem;
    selected: boolean;
    onSelect: (w: WorkspaceItem) => void;
}

const WorkspaceItemMinimal: React.FC<WorkspaceItemMinimalProps> = ({
    workspace,
    selected,
    onSelect,
}) => {
    const onClick = useCallback(() => {
        onSelect(workspace);
    }, [workspace]);

    return (
        <Box
            sx={{
                mb: 1,
                borderBottom: '1px solid #EEE',
            }}
        >
            <Card
                parentProps={{ alignItems: 'center', gap: 0 }}
                sx={{
                    boxShadow: 'none',
                }}
                leftAdornment={
                    <Stack
                        onClick={onClick}
                        justifyContent="center"
                        sx={{
                            py: 1,
                            cursor: 'pointer',
                        }}
                    >
                        <Box
                            sx={{
                                mr: '14px',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: selected ? '5px' : '2px',
                                borderColor: selected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '50%',
                            }}
                        />
                    </Stack>
                }
            >
                <Stack
                    direction="row"
                    gap={1}
                    onClick={onClick}
                    sx={{
                        p: 2,
                        cursor: 'pointer',
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography
                            sx={{
                                color: '#B7BCC7',
                                fontSize: '12px',
                            }}
                        >
                            {workspace.name}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

export default WorkspaceItemMinimal;
