/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { GetEventsResItem0BookingsItem0AccessDto } from './GetEventsResItem0BookingsItem0AccessDto';
import type { GetEventsResItem0BookingsItem0FinancialsDto } from './GetEventsResItem0BookingsItem0FinancialsDto';
import type { GetEventsResItem0BookingsItem0NotesDto } from './GetEventsResItem0BookingsItem0NotesDto';
import type { GetEventsResItem0BookingsItem0OrderDto } from './GetEventsResItem0BookingsItem0OrderDto';
import type { GetEventsResItem0BookingsItem0Source0Dto } from './GetEventsResItem0BookingsItem0Source0Dto';
import type { GetEventsResItem0BookingsItem0Source1Dto } from './GetEventsResItem0BookingsItem0Source1Dto';
import type { GetEventsResItem0BookingsItem0TransactionsDto } from './GetEventsResItem0BookingsItem0TransactionsDto';
import type { GetEventsResItem0BookingsItem1EventDto } from './GetEventsResItem0BookingsItem1EventDto';
import type { GetEventsResItem0BookingsItem1ProductDto } from './GetEventsResItem0BookingsItem1ProductDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';

export type GetBookingsResItem1Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetBookingsResItem1Dto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Source of the booking
     */
    source: (GetEventsResItem0BookingsItem0Source0Dto | GetEventsResItem0BookingsItem0Source1Dto);
    /**
     * Order reference
     */
    product: GetEventsResItem0BookingsItem1ProductDto;
    /**
     * Booked event
     */
    event?: GetEventsResItem0BookingsItem1EventDto;
    /**
     * Order reference
     */
    order: GetEventsResItem0BookingsItem0OrderDto;
    /**
     * List of transactions
     */
    transactions?: GetEventsResItem0BookingsItem0TransactionsDto;
    /**
     * List of transactions
     */
    financials: GetEventsResItem0BookingsItem0FinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: GetEventsResItem0BookingsItem0NotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: GetEventsResItem0BookingsItem0AccessDto;
};

export namespace GetBookingsResItem1Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
