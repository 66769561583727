/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';
import type { ProductTag } from './ProductTag';

export type UpdateProductReqDto = {
    /**
     * Name of the product.
     */
    name?: string;
    /**
     * Detailed textual description of the product.
     */
    description?: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type?: UpdateProductReqDto.type;
    /**
     * Languages in which the product/service is provided
     */
    languages: Array<Language>;
};

export namespace UpdateProductReqDto {

    /**
     * Type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
