import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Stack } from '@mui/material';
import {
    AgebandName,
    AgeBands,
    AvailableProductOption,
    PaxData,
    PricingType,
    Product,
    ProductOptionType,
} from '@travelity/api';
import { CardList } from '@travelity/form';
import { Tag } from '@travelity/ui';
import { useFormContext } from 'react-hook-form';
import { useSelectOptions } from '../../hooks';
import { OptionModal } from '../option-modal';

export interface BookingProductOptionsFormProps {
    bookingPax: PaxData;
    product: Product;
    preview?: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const BookingProductOptionsForm: React.FC<
    BookingProductOptionsFormProps
> = props => {
    const { t } = useTranslation('product');
    const { product, preview, bookingPax } = props;

    const productOptions = useMemo(() => {
        return (
            product.options?.items.map(o => ({
                id: o.id as string,
                name: o.name as string,
                type: o.optionType as ProductOptionType,
                pax: {},
                hasPrice: !!o.prices,
                pricing: o.prices,
            })) || []
        );
    }, []);

    const options = useSelectOptions(productOptions, 'name', 'name', true);

    const { watch } = useFormContext();
    const selectedOptionsArray: AvailableProductOption[] =
        watch('options') || [];
    const selectedOptions =
        selectedOptionsArray && selectedOptionsArray.length
            ? selectedOptionsArray
            : undefined;

    // Handle option selection
    const [optionModal, setOptionModal] = useState<{
        item?: Partial<AvailableProductOption>;
        callback: (item: AvailableProductOption) => void;
    } | null>(null);

    const onOptionAdd = useCallback(
        (callback: (item: AvailableProductOption) => void) => {
            setOptionModal({ item: { pax: bookingPax }, callback });
        },
        [bookingPax]
    );

    const onOptionEdit = useCallback(
        (
            item: Record<string, any>,
            callback: (item: Record<string, any>) => void
        ) => {
            setOptionModal({ item: item as AvailableProductOption, callback });
        },
        []
    );

    const handleOptionSave = useCallback(
        (newItem: AvailableProductOption) => {
            optionModal?.callback(newItem);
            setOptionModal(null);
        },
        [optionModal]
    );

    const availableOptions = useMemo(() => {
        return options.filter(
            option =>
                optionModal?.item?.name === option.name ||
                !selectedOptions?.find(({ name }) => name === option.name)
        );
    }, [options, selectedOptions, optionModal]);

    const getOptionPrice = (n: string) => {
        const option = productOptions?.find(({ name }) => name === n);
        if (option?.pricing?.[0]) {
            const pricing = option?.pricing?.[0];
            const selectedPax = selectedOptionsArray?.find(
                o => o.name === n
            )?.pax;
            const p = selectedPax || bookingPax;
            const count =
                Object.values(p)
                    .filter(v => !!v)
                    .reduce((sum, ageBand) => sum + ageBand, 0) || 0;
            const prices: { count: number; price: number; label: string }[] =
                [];
            if (pricing.type === PricingType.PER_PERSON) {
                prices.push({
                    count,
                    price: pricing.price.perPerson || 0,
                    label: '',
                });
            } else if (pricing.type === PricingType.PER_PRODUCT) {
                prices.push({
                    count: 1,
                    price: pricing.price.perProduct || 0,
                    label: '',
                });
            } else {
                Object.values(AgebandName).forEach((k: AgebandName) => {
                    // @ts-ignore
                    const pr = pricing?.price.counts[k];
                    if (pr && p?.[k]) {
                        prices.push({
                            count: p[k] as number,
                            price: pr,
                            label: k,
                        });
                    }
                });
            }

            if (pricing?.price.base) {
                prices.push({
                    count: 1,
                    price: pricing.price.base,
                    label: '',
                });
            }

            const totalPrice = prices.reduce(
                (s, cur) => s + cur.count * cur.price,
                0
            );
            return totalPrice
                ? `${numberWithSpaces(totalPrice)} ${pricing?.currency}`
                : 'Free';
        }
        return 'Free';
    };

    const hasOptionPrice = (n: string) => {
        return options.find(o => o.name === n && !!o.hasPrice);
    };

    return (
        <>
            <Box
                sx={{
                    minHeight: 0,
                    flexGrow: 2,
                }}
            >
                {!!options?.length && (
                    <CardList
                        disabled={preview}
                        renderHeader={({ item }) => (
                            <Box component="span">
                                {item.name} ({item.type})
                            </Box>
                        )}
                        renderContent={({ item }) => (
                            <Stack direction="row">
                                <Stack
                                    direction="row"
                                    gap={1}
                                    px={1}
                                    py={1}
                                    flexWrap="wrap"
                                    flexGrow={2}
                                >
                                    {Object.values(AgeBands).map(
                                        name =>
                                            !!item.pax[name] && (
                                                <Tag
                                                    label={t(name)}
                                                    values={[item.pax[name]]}
                                                />
                                            )
                                    )}
                                </Stack>
                                <Stack pr={1}>
                                    {!hasOptionPrice(item.name) && (
                                        <Chip
                                            size="small"
                                            label="Free"
                                            sx={{
                                                mt: 1,
                                                color: 'success.main',
                                                border: '1px solid',
                                                borderColor: 'success.main',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                '& > .MuiChip-label': {
                                                    px: '6px',
                                                    py: '4px',
                                                },
                                                bgcolor:
                                                    'rgba(44, 172, 96, 0.20)',
                                            }}
                                        />
                                    )}
                                    {hasOptionPrice(item.name) && (
                                        <Chip
                                            size="small"
                                            label={getOptionPrice(item.name)}
                                            sx={{
                                                mt: 1,
                                                color: 'secondary.main',
                                                border: '1px solid',
                                                borderColor: 'secondary.main',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                '& > .MuiChip-label': {
                                                    px: '6px',
                                                    py: '4px',
                                                },
                                                bgcolor:
                                                    'rgba(85, 181, 207, 0.2)',
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Stack>
                        )}
                        disableAdding={
                            options.length <= (selectedOptions?.length || 0)
                        }
                        customAdd={onOptionAdd}
                        customEdit={onOptionEdit}
                        noEmptyState={preview}
                        name="options"
                        addButtonText="Add Product Option"
                    />
                )}
            </Box>
            <OptionModal
                handleCancel={() => setOptionModal(null)}
                handleConfirm={handleOptionSave}
                open={!!optionModal}
                options={availableOptions}
                item={optionModal?.item}
                productPax={bookingPax}
            />
        </>
    );
};

export default React.memo(BookingProductOptionsForm);
