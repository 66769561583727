/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemMembersItemReferenceOrganizationContactDetailsDto } from './GetTeamsResItemMembersItemReferenceOrganizationContactDetailsDto';

export type GetTeamsResItemMembersItemReferenceOrganizationDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: GetTeamsResItemMembersItemReferenceOrganizationDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: GetTeamsResItemMembersItemReferenceOrganizationContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: string;
};

export namespace GetTeamsResItemMembersItemReferenceOrganizationDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        ORGANIZATION = 'organization',
    }


}
