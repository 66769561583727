import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    Box,
    ClickAwayListener,
    Divider,
    InputAdornment,
    List,
    MenuItem as MuiMenuItem,
    Paper,
    Popper,
    SelectProps as MuiSelectProps,
    Stack,
} from '@mui/material';
import { get } from 'lodash';
import MuiTextField from '@mui/material/TextField';
import { useCreateAsset, useDebouncedAssets, Asset } from '@travelity/api';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { TagListItem } from '@travelity/ui';
import Transitions from '../transitions/transitions';
import CreateAssetModal from './components/create-asset-modal';

export interface SelectAssetOption {
    value: string;
    label: string;

    [key: string]: any;
}

export interface SelectAssetProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    usedAssetIds?: string[];
    MenuItem?: React.FC<any>;
}

const DefaultMenuItem: React.FC<Asset> = props => {
    const { licensePlate, model, make } = props;
    return (
        <Stack
            sx={{
                lineHeight: '12px',
                px: '8px',
                width: 1,
                flexWrap: 'nowrap',
            }}
            direction="row"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    py: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {make}/{model}
            </Box>
            <TagListItem
                sx={{ m: 0, cursor: 'pointer' }}
                label={licensePlate}
            />
        </Stack>
    );
};

const CreateNewMenuItem: React.FC = () => {
    return (
        <Box
            sx={{
                width: '100%',
                color: '#36869C',
                fontSize: '14px',
                lineHeight: '14px',
                textAlign: 'center',
                textDecorationLine: 'underline',
            }}
        >
            Add Asset
        </Box>
    );
};

const StyledSpinner = styled(RefreshIcon)(() => ({
    animation: `nfLoaderSpin infinite 700ms linear`,
    transformBox: 'fill-box',
    color: '#B2B9CD',

    '@keyframes nfLoaderSpin': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

const SelectAsset: React.FC<SelectAssetProps> = ({
    width,
    size,
    name,
    required,
    label,
    placeholder,
    showErrors,
    usedAssetIds,
    MenuItem = DefaultMenuItem,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: undefined,
    });

    const [createNew, setCreateNew] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const { data: assets, isLoading } = useDebouncedAssets(searchText);
    const unusedAssets = useMemo(() => {
        return assets?.filter(asset => !usedAssetIds?.includes(asset.id));
    }, [usedAssetIds, assets]);
    // console.log(assets, isLoading);

    // const onClose = useCallback((e: SyntheticEvent<Element, Event>) => {
    //     if ((e.target as Element).id === 'search') return;
    //     setOpen(false);
    //     setSearchText('');
    // }, []);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLElement | null>(null);

    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };

    const { mutate: createAsset } = useCreateAsset();
    useEffect(() => {
        const t = setTimeout(() => {}, 1000);

        return () => clearTimeout(t);
    }, [createAsset]);

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    const selectedAsset = useMemo(() => {
        return value
            ? `${value.model}/${value.make} ${value.licensePlate}`
            : undefined;
    }, [value]);

    const isOpen = open && !!searchText && !!assets;

    return (
        <>
            <MuiTextField
                margin="dense"
                size={size}
                InputLabelProps={{
                    required: !!required,
                    shrink: !!placeholder,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading && <StyledSpinner />}
                            {!!value && (
                                <CheckCircleIcon sx={{ color: '#37C16E' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
                ref={inputRef}
                label={label}
                placeholder={placeholder}
                value={selectedAsset || searchText}
                onChange={e => {
                    onChange(undefined);
                    setSearchText(e.target.value);
                }}
                type="text"
                error={!!error}
                helperText={error}
                onFocus={() => setOpen(true)}
                onBlur={e => {
                    if (e.relatedTarget && listRef.current) {
                        if (listRef.current.contains(e.relatedTarget as Node)) {
                            return;
                        }
                    }
                    setOpen(false);
                }}
                sx={{
                    m: 0,
                    width: width || '388px',
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(isOpen && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
            />

            <Popper
                placement="bottom-end"
                open={isOpen}
                anchorEl={inputRef.current}
                role={undefined}
                transition
                // disablePortal
                sx={{ zIndex: 10000 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '24px',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                border: '1px solid #DFE1ED',
                                borderTop: 0,
                                background: '#FFF',
                                minHeight: '80px',
                                maxHeight: '200px',
                                width: inputRef.current?.clientWidth,
                                overflowY: 'auto',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    ref={listRef}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '24px',
                                        py: 0.5,
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5,
                                        },
                                    }}
                                >
                                    <Divider sx={{ mx: 2 }} />
                                    {unusedAssets?.map((asset, i) => (
                                        <>
                                            {i !== 0 && <Divider />}
                                            <MuiMenuItem
                                                sx={{
                                                    fontSize: '12px',
                                                    color: '#2B395B',
                                                    borderRadius: '8px',
                                                    py: '4px',
                                                    px: '4px',
                                                    mx: 2,
                                                    my: 1,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#EAF2F4',
                                                    },
                                                }}
                                                key={asset.id}
                                                onClick={() => {
                                                    onChange(asset);
                                                    setOpen(false);
                                                }}
                                            >
                                                <MenuItem {...asset} />
                                            </MuiMenuItem>
                                        </>
                                    ))}
                                    {unusedAssets && !unusedAssets.length && (
                                        <Box
                                            sx={{
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: '12px',
                                                color: '#C0C4CE',
                                                m: 2,
                                                px: 1,
                                            }}
                                        >
                                            No asset found
                                        </Box>
                                    )}
                                    <MuiMenuItem
                                        sx={{
                                            fontSize: '12px',
                                            color: '#2B395B',
                                            borderRadius: '8px',
                                            textAlign: 'center',
                                            py: '4px',
                                            px: '4px',
                                            mx: 2,
                                            my: 1,
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        onClick={() => {
                                            setCreateNew(true);
                                            setOpen(false);
                                        }}
                                    >
                                        <CreateNewMenuItem />
                                    </MuiMenuItem>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <CreateAssetModal
                handleCancel={() => setCreateNew(false)}
                handleConfirm={(asset: Asset) => {
                    onChange(asset);
                    setCreateNew(false);
                }}
                open={createNew}
            />
        </>
    );
};

export default SelectAsset;
