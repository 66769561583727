import { Box, Divider, Skeleton, Stack } from '@mui/material';
import React from 'react';

export const OpenOrderSkeleton = () => {
    return (
        <>
            <Divider />
            <Stack direction="row" gap={1.5}>
                <Box>
                    <Skeleton
                        variant="circular"
                        sx={{
                            width: '55px',
                            height: '55px',
                            color: '#B2B9CD',
                            fontSize: '20px',
                            fontWeight: 700,
                            bgcolor: '#F4F6FA',
                        }}
                    />
                </Box>
                <Box>
                    <Skeleton
                        variant="text"
                        width={200}
                        sx={{
                            fontWeight: 600,
                            color: '#2B395B',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        width={120}
                        sx={{
                            color: '#959CAD',
                        }}
                    />
                </Box>
            </Stack>
        </>
    );
};
