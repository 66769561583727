import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
    useSetTravelerParticipation,
    Traveler,
    useCustomer,
} from '@travelity/api';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { Card, IconButton, Tag } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import { AttendanceType } from '@travelity/api/src/requests';
import { DataRow } from '../../data-row';

export interface BookingTravelerCardProps {
    traveler: Traveler;
    bookingId: string;
}

const BookingTravelerCard: React.FC<BookingTravelerCardProps> = ({
    traveler,
    bookingId,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [showDetails, setShowDetails] = React.useState(false);

    const { mutate: setParticipation, isLoading: isSaving } =
        useSetTravelerParticipation({
            onError: () => {
                enqueueSnackbar(`Failed to set traveler participation`, {
                    variant: 'success',
                });
            },
        });

    const { data: customer, isLoading: isCustomerLoading } = useCustomer(
        traveler.customerId,
        {
            enabled: showDetails,
        }
    );

    const emails = customer?.emails?.map(({ value }) => value) || [];
    const phoneNumbers =
        customer?.numbers?.map(({ value }) => `+${value}`) || [];

    return (
        <Card
            sx={{ minHeight: '122px' }}
            key={traveler.travelerId}
            loadingOverlay={isSaving}
            buttons={
                <Stack gap={0.5}>
                    <IconButton
                        icon={<EmojiPeopleIcon />}
                        withShadow
                        disabledColor="#2CAC60"
                        hoverColor="#2CAC60"
                        disabled={
                            traveler.attendance === AttendanceType.PRESENCE
                        }
                        onClick={() => {
                            setParticipation({
                                bookingId,
                                travelerId: traveler.travelerId,
                                attendance: AttendanceType.PRESENCE,
                            });
                        }}
                    />
                    <IconButton
                        icon={<PersonOffIcon />}
                        withShadow
                        disabledColor="#D84F5F"
                        hoverColor="#D84F5F"
                        disabled={
                            traveler.attendance === AttendanceType.ABSENCE
                        }
                        onClick={() => {
                            setParticipation({
                                bookingId,
                                travelerId: traveler.travelerId,
                                attendance: AttendanceType.ABSENCE,
                            });
                        }}
                    />
                    <IconButton
                        icon={<AccountBoxIcon />}
                        withShadow
                        pressed={showDetails}
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    py: '22px',
                    px: 2,
                    color: '#9298ab',
                    bgcolor: '#f7f8fa',
                    height: '36px',
                    fontWeight: 600,
                    borderRadius: '12px 12px 0px 0px',
                    lineHeight: '10px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Stack
                    direction="row"
                    sx={{ width: 1 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        component="span"
                        title={traveler.fullName}
                        sx={{
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            lineHeight: 2,
                        }}
                    >
                        {traveler.fullName}
                    </Box>
                    <Tag label="Type" value="Traveler" />
                </Stack>
            </Box>
            <Stack gap={1.5} p={2}>
                <DataRow
                    label="Accompanying Travelers"
                    value={`${traveler.guestCount || 0}`}
                    darkValue
                    tagValue
                />
                {showDetails && (
                    <>
                        <Divider>Contact Details</Divider>
                        <DataRow
                            label="Emails"
                            value={emails.join(', ')}
                            isLoading={isCustomerLoading}
                        />
                        <DataRow
                            label="Phone Numbers"
                            value={phoneNumbers.join(', ')}
                            isLoading={isCustomerLoading}
                        />
                    </>
                )}
            </Stack>
        </Card>
    );
};

export default React.memo(BookingTravelerCard);
