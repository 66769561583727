/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateTeamResMembersItemReferenceDto } from './CreateTeamResMembersItemReferenceDto';

export type CreateBookingResSource1Dto = {
    /**
     * type of the source
     */
    type: CreateBookingResSource1Dto.type;
    /**
     * List of customers
     */
    reference: CreateTeamResMembersItemReferenceDto;
};

export namespace CreateBookingResSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
