/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateAssetResAccessDto } from './CreateAssetResAccessDto';
import type { CreateAssetResOwnerDto } from './CreateAssetResOwnerDto';
import type { CreateAssetResUsageItemDto } from './CreateAssetResUsageItemDto';
import type { CreateAssetResUsageItemMileageDto } from './CreateAssetResUsageItemMileageDto';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';
import type { VehicleEngineType } from './VehicleEngineType';
import type { VehicleType } from './VehicleType';

export type CreateAssetResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateAssetResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * type of the asset
     */
    type: CreateAssetResDto.type;
    /**
     * Asset owner data
     */
    owner?: CreateAssetResOwnerDto;
    /**
     * Usage history of the asset
     */
    usage?: Array<CreateAssetResUsageItemDto>;
    /**
     * Access to the shared product
     */
    access: CreateAssetResAccessDto;
    /**
     * type of the asset
     */
    vehicle_type: VehicleType;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    engine_type: VehicleEngineType;
    /**
     * Vehicle mileage
     */
    mileage: CreateAssetResUsageItemMileageDto;
};

export namespace CreateAssetResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ASSET = 'asset',
    }

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
