/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetProductsResItem0AccessDto } from './GetProductsResItem0AccessDto';
import type { GetProductsResItem0CapacityDto } from './GetProductsResItem0CapacityDto';
import type { GetProductsResItem0ConfigurationDto } from './GetProductsResItem0ConfigurationDto';
import type { GetProductsResItem0OptionsDto } from './GetProductsResItem0OptionsDto';
import type { GetProductsResItem0PricingDto } from './GetProductsResItem0PricingDto';
import type { GetProductsResItem0RouteDto } from './GetProductsResItem0RouteDto';
import type { GetProductsResItem0ScheduleDto } from './GetProductsResItem0ScheduleDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { Language } from './Language';
import type { ProductTag } from './ProductTag';

export type GetProductsResItem0Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetProductsResItem0Dto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Detailed textual description of the product.
     */
    description: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type: GetProductsResItem0Dto.type;
    /**
     * Indicates whether the product is active or not
     */
    active: boolean;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration: GetProductsResItem0ConfigurationDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    capacity: GetProductsResItem0CapacityDto;
    /**
     * Defines the recurrent and non-recurrent items of the schedule
     */
    schedule: GetProductsResItem0ScheduleDto;
    /**
     * Financial information of the product.
     */
    pricing?: GetProductsResItem0PricingDto;
    /**
     * Optional items and services for this product
     */
    options?: GetProductsResItem0OptionsDto;
    /**
     * Access to the shared product
     */
    access: GetProductsResItem0AccessDto;
    /**
     * Route of the tour/transfer
     */
    route: GetProductsResItem0RouteDto;
    /**
     * Languages in which the product/service is provided
     */
    languages: Array<Language>;
};

export namespace GetProductsResItem0Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        PRODUCT = 'product',
    }

    /**
     * Type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
