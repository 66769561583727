/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetEventsResItem0BookingsItem0Dto } from './GetEventsResItem0BookingsItem0Dto';
import type { GetEventsResItem0BookingsItem0ProductCustomersItemReferenceDto } from './GetEventsResItem0BookingsItem0ProductCustomersItemReferenceDto';
import type { GetEventsResItem0BookingsItem1Dto } from './GetEventsResItem0BookingsItem1Dto';
import type { GetOrdersResItemAccessDto } from './GetOrdersResItemAccessDto';
import type { GetOrdersResItemPriceDto } from './GetOrdersResItemPriceDto';
import type { GetOrdersResItemSummaryDto } from './GetOrdersResItemSummaryDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { OrderStatus } from './OrderStatus';

export type GetOrdersResItemDto = {
    /**
     * Type of the resource
     */
    resource_type: GetOrdersResItemDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Order number which will be displayed to the customer
     */
    number: string;
    /**
     * Information about the customer who booked the event.
     */
    customer: GetEventsResItem0BookingsItem0ProductCustomersItemReferenceDto;
    /**
     * Price information.
     */
    price?: GetOrdersResItemPriceDto;
    /**
     * Status of the order
     */
    status: OrderStatus;
    /**
     * List of traveler preferences
     */
    bookings?: Array<(GetEventsResItem0BookingsItem0Dto | GetEventsResItem0BookingsItem1Dto)>;
    /**
     * Access to the shared product
     */
    access: GetOrdersResItemAccessDto;
    /**
     * Order summary information.
     */
    summary?: GetOrdersResItemSummaryDto;
};

export namespace GetOrdersResItemDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}
