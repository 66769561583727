import { format } from 'date-fns';
import {
    GetCustomerResItemDto,
    type GetEventsResItem0BookingsItem0Source1ReferenceDto,
} from '../../requests';
import { Customer } from './customer.types';
import { convertNameDtoToFullName } from '../user/user.converters';

export const getCustomerDtoToCustomer = (
    customer:
        | Omit<GetCustomerResItemDto, 'created' | 'resource_type' | 'access'>
        | Omit<
              GetEventsResItem0BookingsItem0Source1ReferenceDto,
              'created' | 'resource_type' | 'access'
          >
): Customer => ({
    // @ts-ignore
    id: customer.id,
    customerId: customer.id,
    fullName: convertNameDtoToFullName(customer.name),
    emails: customer.contact_details?.emails?.map(value => ({ value })) || [],
    numbers:
        customer.contact_details?.phone_numbers?.map(number => ({
            value: `${number.calling_code} ${number.number}`,
        })) || [],
    birthDate: customer.birth_details?.date
        ? format(new Date(customer.birth_details.date * 1000), 'dd.MM.yyyy')
        : undefined,
    birthPlace: customer.birth_details?.place?.name,
    nationality: customer.birth_details?.nationality,
    passportN: customer.passport?.number,
    issuedBy: customer.passport?.authority,
    issuedAt: customer.passport?.issued_at
        ? format(new Date(customer.passport.issued_at * 1000), 'dd.MM.yyyy')
        : undefined,
    expiresAt: customer.passport?.expires_at
        ? format(new Date(customer.passport.expires_at * 1000), 'dd.MM.yyyy')
        : undefined,
    citizenship: customer.passport?.citizenship,
    languages: customer.languages || [],
    attachments:
        customer.attachments?.map(attachment => ({
            name: attachment.name,
            contentType: attachment.content_type,
            content: '',
            url: attachment.url || '',
            uuid: attachment.id,
        })) || [],
});
