/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BirthDetailsData } from './BirthDetailsData';
import type { Language } from './Language';
import type { Passport } from './Passport';
import type { PersonNameData } from './PersonNameData';
import type { UpdateCustomerResAccessDto } from './UpdateCustomerResAccessDto';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';
import type { UpdateUserResProfileAttachmentsItemDto } from './UpdateUserResProfileAttachmentsItemDto';
import type { UpdateUserResProfileContactDetailsDto } from './UpdateUserResProfileContactDetailsDto';
import type { UpdateUserResProfileOrganizationDto } from './UpdateUserResProfileOrganizationDto';

export type UpdateCustomerResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateCustomerResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: UpdateUserResProfileContactDetailsDto;
    /**
     * List of paths in the storage where the passport scans/uploads are.
     */
    attachments?: Array<UpdateUserResProfileAttachmentsItemDto>;
    /**
     * Person's name, separated into components.
     */
    name?: PersonNameData;
    /**
     * Birth details of the person.
     */
    birth_details?: BirthDetailsData;
    /**
     * Passport details of the person.
     */
    passport?: Passport;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages?: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: UpdateUserResProfileOrganizationDto;
    /**
     * Access to the shared product
     */
    access?: UpdateCustomerResAccessDto;
};

export namespace UpdateCustomerResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CUSTOMER = 'customer',
    }


}
