/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamResAccessDto } from './GetTeamResAccessDto';
import type { GetTeamResAccountDto } from './GetTeamResAccountDto';
import type { GetTeamResMembersDto } from './GetTeamResMembersDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { TeamInsightData } from './TeamInsightData';

export type GetTeamResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetTeamResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Name of the team
     */
    name: string;
    /**
     * Name of the team
     */
    description?: string;
    /**
     * Reference of the team lead
     */
    members: GetTeamResMembersDto;
    /**
     * summary of shared products
     */
    insight?: TeamInsightData;
    /**
     * Access to the shared product
     */
    access: GetTeamResAccessDto;
    /**
     * Account details of the user
     */
    account: GetTeamResAccountDto;
};

export namespace GetTeamResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        TEAM = 'team',
    }


}
