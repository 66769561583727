import React from 'react';
import { Box, Divider, Skeleton, Stack } from '@mui/material';

export interface ListMainProps {
    children: React.ReactNode;
    isLoading?: boolean;
    SkeletonComponent?: React.FC;
    hideOnPrint?: boolean;
}

export const ListMain: React.FC<ListMainProps> = React.memo(
    (props: ListMainProps) => {
        const { children, isLoading, hideOnPrint, SkeletonComponent } = props;

        return (
            <Box
                className={hideOnPrint ? 'hide-on-print' : ''}
                sx={{
                    bgcolor: '#FFF',
                    flexGrow: 2,
                    ml: 2,
                    my: 2,
                    px: 2,
                    height: 'calc(100% - 32px)',
                    minWidth: '0',
                    overflow: isLoading ? 'hidden' : 'initial',
                }}
            >
                {children}
                {isLoading && SkeletonComponent && (
                    <>
                        <Divider>
                            <Skeleton width={100} />
                        </Divider>

                        <Box
                            sx={{
                                py: 2,
                                bgcolor: '#F8F9FA',
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <SkeletonComponent key={v} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
        );
    }
);
