import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    Paper,
    Popper,
    Tooltip,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { omit } from 'lodash';
import { Heading } from '@travelity/ui';
import Transitions from '../../../transitions/transitions';
import { FilterListItem } from '../filter-list-item';
import { FilterSettings } from '../filter-settings';
import { FilterOption, FilterValue } from '../../filters.types';

export interface FiltersProps {
    selectedFilters: Record<string, FilterValue>;
    updateSelectedFilters: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const FilterButton: React.FC<FiltersProps> = memo(
    (props: FiltersProps) => {
        const { selectedFilters, updateSelectedFilters, options } = props;

        const [values, setValues] = useState<Record<string, FilterValue>>({});

        // const nothingChanged = useMemo(() => {
        //     return isEqual(values, selectedFilters);
        // }, [values, selectedFilters]);

        // Popper state
        const [open, setOpen] = useState(false);
        const anchorRef = useRef<HTMLButtonElement | null>(null);

        const handleClose = (
            event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
        ) => {
            if ((event.target as HTMLElement)?.localName === 'body') {
                return;
            }
            if (
                anchorRef.current &&
                anchorRef.current.contains(event.target as Node)
            ) {
                return;
            }
            setOpen(false);
        };

        const handleToggle = () => {
            if (!open) {
                setValues(selectedFilters);
            }
            setOpen(!open);
        };

        // Selected filter to configure
        const [activeFilterName, setActiveFilterName] = useState<
            string | undefined
        >();
        useEffect(() => {
            if (open) {
                setActiveFilterName(undefined);
            }
        }, [open]);
        const activeFilter = useMemo(
            () =>
                activeFilterName
                    ? {
                          value: values[activeFilterName],
                          option: options.find(
                              o => o.name === activeFilterName
                          ) as FilterOption,
                      }
                    : null,
            [activeFilterName, options, values]
        );

        const changeFilter = useCallback((name: string) => {
            setActiveFilterName(name);
        }, []);

        // Update a filter
        const updateFilter = useCallback(
            (value: any) => {
                updateSelectedFilters({
                    ...values,
                    [activeFilterName as string]: value,
                });
                setOpen(false);
                setActiveFilterName(undefined);
            },
            [activeFilterName, values]
        );

        // Remove a filter
        const clearFilter = useCallback(
            (name: string) => {
                const newValues = omit(values, [name]);
                setValues(newValues);
                updateSelectedFilters(newValues);
            },
            [values, setValues]
        );

        // Split filters to selected / not selected
        const unused = useMemo(() => {
            return options.filter(o => !values[o.name]);
        }, [options, values]);
        const selectedOptions = useMemo(() => {
            return options.filter(o => values[o.name]);
        }, [options, values]);

        return (
            <>
                <Tooltip title="Customise Filter">
                    <IconButton
                        color="secondary"
                        sx={{
                            bgcolor: '#FFF',
                            boxShadow:
                                '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                        }}
                        ref={anchorRef}
                        onClick={handleToggle}
                    >
                        <TuneIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                </Tooltip>

                <Popper
                    placement="bottom-end"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    sx={{ zIndex: 1200 }}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions in={open} {...TransitionProps}>
                            <Paper
                                sx={{
                                    borderRadius: '12px',
                                    boxShadow:
                                        '0px 0px 16px 0px rgba(178, 185, 205, 0.40)',
                                    background: '#FFF',
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Box sx={{ p: 2 }}>
                                        {activeFilter ? (
                                            <FilterSettings
                                                filter={activeFilter.option}
                                                value={activeFilter.value}
                                                onCancel={() =>
                                                    setActiveFilterName(
                                                        undefined
                                                    )
                                                }
                                                selectedFilters={
                                                    selectedFilters
                                                }
                                                onUpdate={updateFilter}
                                            />
                                        ) : (
                                            <>
                                                <Heading
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Filter Configuration
                                                </Heading>
                                                {!!selectedOptions.length && (
                                                    <>
                                                        <Divider sx={{ mt: 1 }}>
                                                            Selected filters
                                                        </Divider>
                                                        <List>
                                                            {selectedOptions.map(
                                                                ({
                                                                    label,
                                                                    name,
                                                                    required,
                                                                }) => (
                                                                    <FilterListItem
                                                                        label={
                                                                            label
                                                                        }
                                                                        selected
                                                                        onClick={() =>
                                                                            changeFilter(
                                                                                name
                                                                            )
                                                                        }
                                                                        onRemove={
                                                                            required
                                                                                ? undefined
                                                                                : () =>
                                                                                      clearFilter(
                                                                                          name
                                                                                      )
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </List>
                                                    </>
                                                )}
                                                {!!unused.length && (
                                                    <>
                                                        <Divider sx={{ mt: 1 }}>
                                                            Filters to Select
                                                        </Divider>
                                                        <List
                                                            component="nav"
                                                            sx={{
                                                                width: '100%',
                                                                borderRadius:
                                                                    '24px',
                                                                py: 0.5,
                                                                '& .MuiListItemButton-root':
                                                                    {
                                                                        mt: 0.5,
                                                                    },
                                                            }}
                                                        >
                                                            {unused.map(
                                                                ({
                                                                    label,
                                                                    name,
                                                                    disable,
                                                                }) => (
                                                                    <FilterListItem
                                                                        label={
                                                                            label
                                                                        }
                                                                        disabled={disable?.(
                                                                            selectedOptions.map(
                                                                                ({
                                                                                    name: n,
                                                                                }) =>
                                                                                    n
                                                                            )
                                                                        )}
                                                                        onClick={() =>
                                                                            changeFilter(
                                                                                name
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </List>
                                                    </>
                                                )}
                                                {/* <Stack */}
                                                {/*    direction="row" */}
                                                {/*    mt={1} */}
                                                {/*    gap={1} */}
                                                {/* > */}
                                                {/*    /!* <Button *!/ */}
                                                {/*    /!*    variant="contained" *!/ */}
                                                {/*    /!*    color="secondary" *!/ */}
                                                {/*    /!*    sx={{ flexGrow: 1 }} *!/ */}
                                                {/*    /!*    // disabled={nothingChanged} *!/ */}
                                                {/*    /!*    onClick={() => *!/ */}
                                                {/*    /!*        updateSelectedFilters( *!/ */}
                                                {/*    /!*            values *!/ */}
                                                {/*    /!*        ) *!/ */}
                                                {/*    /!*    } *!/ */}
                                                {/*    /!* > *!/ */}
                                                {/*    /!*    Apply *!/ */}
                                                {/*    /!* </Button> *!/ */}
                                                {/*    <Button */}
                                                {/*        variant="contained" */}
                                                {/*        color="secondary" */}
                                                {/*        sx={{ flexGrow: 2 }} */}
                                                {/*        // disabled={nothingChanged} */}
                                                {/*        onClick={() => { */}
                                                {/*            updateSelectedFilters( */}
                                                {/*                values */}
                                                {/*            ); */}
                                                {/*            setOpen(false); */}
                                                {/*        }} */}
                                                {/*    > */}
                                                {/*        Update Filters */}
                                                {/*    </Button> */}
                                                {/* </Stack> */}
                                            </>
                                        )}
                                    </Box>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </>
        );
    }
);
