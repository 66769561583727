import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Booking, PrintData, useCancelOrder, useOrder } from '@travelity/api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import {
    BookingItemSkeleton,
    BookingItem,
} from '../../components/booking-item';
import { BookingPreview } from '../../components/booking-preview';

import OrderPreview from '../orders/components/order-preview';
import { ReasonModal } from '../../components/booking-item/components/reason-modal';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
} from '../../components/list-layout';
import { OrderDetails } from './components/order-details';
import { SidebarContent } from '../../components/sidebar-content';
import { useNavigateAbsolute } from '../../hooks';
import { PrintOrder } from './components/print-order';
import PrintDataModal from './components/print-data-modal';

function OrderPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { bookingId, orderId, preview } = useParams() as {
        bookingId?: string;
        orderId: string;
        preview?: string;
    };
    const navigate = useNavigateAbsolute();

    const [openPrint, setOpenPrint] = useState(false);
    const [printData, setPrintData] = useState<PrintData>();

    const { data: order, isLoading, refetch, isRefetching } = useOrder(orderId);
    const booking = useMemo(() => {
        return bookingId
            ? order?.bookings?.find(({ id }) => id === bookingId)
            : undefined;
    }, [order, bookingId]);

    const [deletingOrder, setDeletingOrder] = useState(false);
    const { mutate: removeOrder, isLoading: isRemoveOrderLoading } =
        useCancelOrder({
            onSuccess: () => {
                setDeletingOrder(false);
                navigate('/orders');
                enqueueSnackbar(
                    `Cancelled the order #${order?.number} of ${order?.participants.customer.fullName}`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(
                    `Failed to cancel the order #${order?.number} of ${order?.participants.customer.fullName}`,
                    {
                        variant: 'error',
                    }
                );
            },
        });

    const siblingBookings: Booking[] = useMemo(() => {
        return booking && order
            ? order.bookings.filter(({ id }) => id !== booking.id)
            : [];
    }, [order?.bookings, booking]);

    const onExportClick = useCallback(() => {
        setOpenPrint(true);
    }, []);

    const onGenerateClick = useCallback((v: PrintData) => {
        setOpenPrint(false);
        setPrintData(v);
        setTimeout(() => {
            window.print();
        }, 1);
    }, []);

    const onDeleteClick = useCallback(() => {
        setDeletingOrder(true);
    }, [setDeletingOrder]);

    return (
        <>
            <Helmet>
                <title>Travelity - Order #{order?.number || ''}</title>
            </Helmet>
            <List>
                {printData && order && (
                    <PrintOrder order={order} printData={printData} />
                )}
                <ListMain
                    hideOnPrint={!!printData}
                    isLoading={isLoading || isRefetching}
                    SkeletonComponent={BookingItemSkeleton}
                >
                    <OrderDetails
                        isLoading={isLoading || isRefetching}
                        orderId={orderId}
                        onExportClick={onExportClick}
                        onDeleteClick={onDeleteClick}
                        order={order}
                    />
                    <ListItems
                        noItemsText=""
                        items={order?.bookings.length}
                        isLoading={isLoading || isRefetching}
                        entityName="booking"
                        entityNamePlural="bookings"
                        subtractHeight={169}
                    >
                        {order?.bookings.map(item => (
                            <BookingItem
                                key={item.id}
                                booking={item}
                                isSelected={item.id === bookingId}
                                refetch={refetch}
                            />
                        ))}
                    </ListItems>
                </ListMain>
                <ListSidebar
                    isEmpty={!bookingId && !preview}
                    placeholderText="No booking is selected for preview"
                >
                    {booking && (
                        <SidebarContent
                            title="Booking Preview"
                            isLoading={isLoading}
                        >
                            <BookingPreview
                                booking={booking}
                                siblingBookings={siblingBookings}
                                updateBooking={refetch}
                            />
                        </SidebarContent>
                    )}
                    {orderId && !!preview && order && (
                        <OrderPreview
                            order={order}
                            isLoading={isLoading}
                            updateOrder={() => {
                                refetch();
                            }}
                        />
                    )}
                </ListSidebar>
            </List>
            <ReasonModal
                title="Are you sure you want to cancel this order?"
                content="Provide the reason for cancelling this order"
                confirmText="Confirm"
                open={deletingOrder}
                isLoading={isRemoveOrderLoading}
                handleCancel={() => setDeletingOrder(false)}
                handleConfirm={reason => {
                    removeOrder({
                        orderId,
                        reason,
                    });
                }}
            />
            <PrintDataModal
                open={openPrint}
                handleConfirm={onGenerateClick}
                handleCancel={() => setOpenPrint(false)}
            />
        </>
    );
}

export default OrderPage;
