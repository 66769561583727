/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';
import type { UpdateUserResProfileContactDetailsDto } from './UpdateUserResProfileContactDetailsDto';
import type { UpdateUserResProfileOrganizationDto } from './UpdateUserResProfileOrganizationDto';

export type CancelEventsResBookingsItem0ProductCustomersItemReferenceDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type?: CancelEventsResBookingsItem0ProductCustomersItemReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: UpdateUserResProfileContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name?: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages?: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: UpdateUserResProfileOrganizationDto;
};

export namespace CancelEventsResBookingsItem0ProductCustomersItemReferenceDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}
