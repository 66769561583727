import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { OrderItem } from '@travelity/api';
import { useForm } from '@travelity/form';

import AccordionButtons from './accordion-buttons';
import { OrderFinancialInformationForm } from '../../../components/order-financial-information';
import financialInformationSchema from '../../../components/order-financial-information/order-financial-information.schema';
import { OrderAttachmentsForm } from '../../../components/order-attachments';
import attachmentsSchema from '../../../components/order-attachments/order-attachments.schema';
import { ActivityHistory } from '../../../components/activity-history';
import { SidebarContent } from '../../../components/sidebar-content';

export interface BookingEditFormProps {
    order: OrderItem;
    isLoading: boolean;
}

const OrderPreview: React.FC<BookingEditFormProps> = ({ order, isLoading }) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>();

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const onSubmit = useCallback(
        (data: any, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
        },
        [order]
    );

    const {
        Form: FinancialInformationForm,
        reset: resetFinancialInformation,
        // formState: { errors: detailsErrors },
    } = useForm({
        defaultValues: order.financials,
        onSubmit,
        mode: 'onChange',
        schema: financialInformationSchema,
    });
    const {
        Form: AttachmentsForm,
        reset: resetAttachments,
        formState: { errors: attachmentsErrors },
    } = useForm({
        defaultValues: { values: [] },
        onSubmit,
        mode: 'onChange',
        schema: attachmentsSchema,
    });

    const reset = useCallback(() => {
        switch (expanded) {
            case 0:
                resetFinancialInformation(order.financials);
                break;
            case 1:
                break;
            case 2:
                resetAttachments({ values: [] });
                break;
            default:
        }
    }, [expanded, order]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [order]);

    // const { setMainIsLoading } = useLayoutContext();
    // useEffect(() => {
    //     setMainIsLoading(isSaving);
    // }, [isSaving]);

    const { canEdit } = order.permissions;

    return (
        <SidebarContent title="Order Preview" isLoading={isLoading}>
            <Box
                sx={{
                    '&&& .MuiAccordionSummary-root': {
                        borderBottom: '1px solid #DFE1ED',
                    },
                    '&&& .MuiAccordionDetails-root': {
                        py: 3,
                    },
                }}
            >
                <FinancialInformationForm id="financialInformation">
                    <Accordion
                        disableGutters
                        disabled
                        expanded={expanded === 0}
                        onChange={() => toggleExpanded(0)}
                    >
                        <AccordionSummary>
                            Pricing and Financials
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrderFinancialInformationForm
                                preview={!editing || expanded !== 0}
                            />
                        </AccordionDetails>
                    </Accordion>
                </FinancialInformationForm>
                <AttachmentsForm id="attachments">
                    <Accordion
                        disableGutters
                        disabled
                        expanded={expanded === 2}
                        onChange={() => toggleExpanded(2)}
                    >
                        <AccordionSummary>
                            Attachments
                            {expanded === 2 && canEdit && (
                                <AccordionButtons
                                    editing={editing}
                                    errors={attachmentsErrors}
                                    onEditCancel={onCancel}
                                    onEdit={() => setEditing(true)}
                                />
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrderAttachmentsForm
                                preview={!editing || expanded !== 2}
                            />
                        </AccordionDetails>
                    </Accordion>
                </AttachmentsForm>
                <Accordion
                    disableGutters
                    expanded={expanded === 3}
                    onChange={() => toggleExpanded(3)}
                >
                    <AccordionSummary>Activity Log</AccordionSummary>
                    <AccordionDetails>
                        <ActivityHistory
                            entity="order"
                            lifecycle={order.lifecycle}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </SidebarContent>
    );
};

export default React.memo(OrderPreview);
