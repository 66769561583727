import { useController, useFormContext } from 'react-hook-form';
import React from 'react';
import _ from 'lodash';
import { useIsFormNested } from '../../nested-form-provider';
import { DesktopTimePicker, DesktopTimePickerProps } from '@mui/x-date-pickers';

export type TimePickerProps = Partial<DesktopTimePickerProps<any>> & {
    name: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
    autoGenValue?: string;
    info?: string;
    showErrors?: boolean;
    regExp?: RegExp;
};

const TimePicker: React.FC<TimePickerProps> = ({
    name,
    required,
    label,
    fullWidth,
    autoGenValue,
    info,
    showErrors,
    regExp,
    slotProps = {},
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({ control, name });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <DesktopTimePicker
            ampm={false}
            views={['hours', 'minutes']}
            {...field}
            {...rest}
            slotProps={{
                ...slotProps,
                textField: {
                    ...slotProps.textField,
                    error: !!error,
                },
            }}
        />
    );
};

export default TimePicker;
