import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
    AgebandName,
    Booking,
    BookingProductPricing,
    BookingStatus,
    DiscountType,
    OrderItem,
    PaxData,
    PricingType,
    PrintData,
    ProductType,
    SourceType,
} from '@travelity/api';
import { format, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
    AgebandPricingData,
    FlatPricingData,
} from '@travelity/api/src/requests';
import { formatMoney } from '@travelity/utils';
import { useUserContext } from '../../../contexts/user';
import { TravelerForm } from '../../../components/booking-participants/booking-participants.types';
import { paxTotal } from '../../../hooks';
import { directSources } from '../../availability/components/booking-side-form';

export interface PrintOrderProps {
    order: OrderItem;
    printData: PrintData;
}

const ageBands = ['Adults', 'Children', 'Infants', 'Youth', 'Seniors'];

const toPlural = (name: string, value: number) => {
    if (value > 1) return `${value} ${name}`;
    if (name === 'children') return `1 child`;
    // TODO remove s
    return `1 ${name.substring(0, name.length - 1)}`;
};

const getUniqPickups = (booking: Booking) => {
    const result: { id?: string; time: string; location: string }[] = [];
    booking.participants.travelers?.forEach(traveler => {
        const { pickUp } = traveler;
        if (
            pickUp &&
            !result.find(p => p.id === pickUp.id && p.location === pickUp.name)
        ) {
            result.push({
                id: pickUp.id,
                time: format(pickUp.time, 'HH:mm'),
                location: pickUp.name,
            });
        }
    });
    return result;
};

export const formatPaxValue = (value: Record<string, number>) =>
    ageBands
        .map(name => name.toLowerCase())
        .filter(name => value[name])
        .map(name => toPlural(name, value[name]))
        .join(', ');

export const formatTravelersValue = (travelers: TravelerForm[]) => {
    return travelers
        .map(
            traveler =>
                `${traveler.fullName}${
                    traveler.guestCount ? ` (+${traveler.guestCount})` : ''
                }`
        )
        .join(', ');
};

export interface PriceValueProps {
    title: string;
    pricing: BookingProductPricing;
    pax: PaxData;
}

const sources = directSources.reduce(
    (obj, cur) => ({
        ...obj,
        [cur.value]: cur.label,
    }),
    {} as Record<string, string>
);

const toPluralX = (name: string, value: number) => {
    if (value > 1) return `${value}x ${name}`;
    if (name === 'children') return `1x child`;
    // TODO remove s
    return `1x ${name.substring(0, name.length - 1)}`;
};

export const Separator = () => {
    return (
        <Box component="span" className="separator" sx={{ px: 0.5 }}>
            {' '}
            |{' '}
        </Box>
    );
};

export const Watermark: React.FC = () => {
    useEffect(() => {
        document.body.className = 'draft';
        return () => {
            document.body.className = '';
        };
    }, []);

    return null;
};

export const PriceValue: React.FC<PriceValueProps> = props => {
    const { title, pricing, pax } = props;
    const base = pricing.price.base_amount || 0;

    const prices: { count: number; price: number; label: string }[] = [];
    if (pricing.type === PricingType.PER_PRODUCT) {
        prices.push({
            count: 1,
            price: (pricing.price as FlatPricingData).unit_amount,
            label: 'products',
        });
    } else if (pricing.type === PricingType.PER_PERSON) {
        prices.push({
            count: paxTotal(pax),
            price: (pricing.price as FlatPricingData).unit_amount,
            label: 'persons',
        });
    } else if (pricing.type === PricingType.PER_AGEBAND) {
        Object.values(AgebandName).forEach((k: AgebandName) => {
            const p = (pricing.price as AgebandPricingData)[k];
            if (p && pax[k]) {
                prices.push({
                    count: pax[k] || 0,
                    price: p,
                    label: k,
                });
            }
        });
    }

    const totalPrice =
        pricing.type === PricingType.PER_PRODUCT
            ? (pricing.price as FlatPricingData).unit_amount || 0
            : prices.reduce((s, p) => s + p.count * p.price, 0) + base;

    return (
        <>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
                {title}
                <Separator />
                <Box component="span">
                    {prices
                        .map(({ label, count }) => toPluralX(label, count))
                        .join(', ')}
                </Box>{' '}
                <Box
                    component="span"
                    sx={{
                        fontWeight: 700,
                        color: '#000',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {formatMoney(totalPrice)}
                </Box>
            </Typography>
            {!!pricing.inclusions?.length && (
                <Typography variant="body2" sx={{ ml: 2 }}>
                    Inclusions
                    {pricing.inclusions.map(inclusion => (
                        <>
                            <Box component="span"> • </Box>
                            <Box
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                    color: '#000',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {inclusion}
                            </Box>
                        </>
                    ))}
                </Typography>
            )}
        </>
    );
};

export const PrintOrder: React.FC<PrintOrderProps> = props => {
    const { order, printData } = props;
    const { user } = useUserContext();
    const {
        companyName = user?.fullName,
        website,
        appendix,
        email = user?.email,
        phone = user?.numbers?.[0]?.value,
        address,
    } = printData;

    const { t } = useTranslation();

    const currency = user?.currency || '';

    const isDraft =
        !!order.bookings.find(b => b.status !== BookingStatus.CANCELLED) &&
        !!order.bookings.find(
            b =>
                b.status !== BookingStatus.CONFIRMED &&
                b.status !== BookingStatus.CANCELLED
        );

    return (
        <Box
            className="show-on-print draft"
            sx={{ bgcolor: '#FFF', position: 'relative' }}
        >
            {isDraft && <Watermark />}

            <Box sx={{ px: 5, margin: '0 auto', color: '#444' }}>
                <Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{
                                pt: 1,
                                pb: 5,
                                mb: 0,
                                color: '#000',
                                lineHeight: 1,
                            }}
                        >
                            {isDraft
                                ? '>>>> ORDER DRAFT <<<<'
                                : '>>>> ORDER CONFIRMATION <<<<'}
                        </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 0,
                                    color: '#000',
                                    lineHeight: 1,
                                }}
                            >
                                Order #
                                <Box
                                    component="span"
                                    className="black-text order-number"
                                >
                                    {order.number}
                                </Box>
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className="subtitle"
                                sx={{
                                    lineHeight: 1,
                                    color: '#999',
                                    pl: '2px',
                                    fontSize: '12px',
                                }}
                            >
                                Generated at{' '}
                                <Box component="span" className="generated-at">
                                    {format(new Date(), 'd MMM yyyy HH:mm')}
                                </Box>
                            </Typography>
                        </Box>
                        <Box
                            className="financial-details"
                            sx={{ textAlign: 'right' }}
                        >
                            {companyName && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {companyName}
                                    </Box>
                                </Typography>
                            )}
                            {!!website && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="a"
                                        target="_blank"
                                        href={website}
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {website}
                                    </Box>
                                </Typography>
                            )}
                            {email && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="a"
                                        href={`mailto:${email}`}
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {email}
                                    </Box>
                                </Typography>
                            )}
                            {phone && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {phone}
                                    </Box>
                                </Typography>
                            )}
                            {address && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text currency"
                                    >
                                        {address}
                                    </Box>
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        className="ordered-by"
                        gap={1}
                        sx={{
                            borderBottom: '2px solid #000',
                            paddingBottom: 1,
                            mt: 2,
                        }}
                    >
                        <Typography variant="body1" className="gray-text">
                            Ordered by:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 700, color: '#000' }}
                            className="customer-name"
                        >
                            {order.participants.customer.fullName}
                        </Typography>
                        {order.participants.customer.emails.length > 0 && (
                            <>
                                <Separator />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 700,
                                        color: '#000',
                                        '& a': { color: '#000' },
                                    }}
                                    className="customer-email"
                                >
                                    {' '}
                                    <a
                                        href={`mailto:${order.participants.customer.emails[0].value}`}
                                    >
                                        {
                                            order.participants.customer
                                                .emails[0].value
                                        }
                                    </a>
                                </Typography>
                            </>
                        )}
                        {order.participants.customer.numbers.length > 0 && (
                            <>
                                <Separator />
                                <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 700, color: '#000' }}
                                    className="customer-number"
                                >
                                    {
                                        order.participants.customer.numbers[0]
                                            .value
                                    }
                                </Typography>
                            </>
                        )}
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        className="details-container"
                        sx={{ marginTop: 2 }}
                    >
                        <Box className="order-details" sx={{ width: '48%' }}>
                            <Typography variant="h6" sx={{ color: '#000' }}>
                                Order Details
                            </Typography>
                            <Typography variant="body2" className="gray-text">
                                Created by{' '}
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 700, color: '#000' }}
                                    className="black-text created-by"
                                >
                                    {order.createdBy}
                                </Box>
                            </Typography>
                            <Typography variant="body2" className="gray-text">
                                Bookings{' '}
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 700, color: '#000' }}
                                    className="black-text bookings"
                                >
                                    {
                                        order.bookings.filter(
                                            booking =>
                                                booking.status !==
                                                BookingStatus.CANCELLED
                                        ).length
                                    }
                                </Box>
                            </Typography>
                        </Box>

                        {order.financials && (
                            <Box
                                className="financial-details"
                                sx={{ width: '48%' }}
                            >
                                <Typography variant="h6" sx={{ color: '#000' }}>
                                    Financial Details
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                >
                                    Currency{' '}
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text currency"
                                    >
                                        {currency}
                                    </Box>
                                </Typography>
                                {order.financials.subtotal &&
                                    order.financials.subtotal !==
                                        order.financials.total && (
                                        <Typography
                                            variant="body2"
                                            className="gray-text"
                                        >
                                            Total before discount{' '}
                                            <Box
                                                component="span"
                                                className="black-text before-discount"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: '#000',
                                                }}
                                            >
                                                {formatMoney(
                                                    order.financials.subtotal
                                                )}
                                            </Box>
                                        </Typography>
                                    )}
                                {order.financials.discount && (
                                    <Typography
                                        variant="body2"
                                        className="gray-text"
                                    >
                                        Discount{' '}
                                        <Box
                                            component="span"
                                            className="black-text discount"
                                            sx={{
                                                fontWeight: 700,
                                                color: '#000',
                                            }}
                                        >
                                            {formatMoney(
                                                order.financials.discount.amount
                                            )}
                                            {order.financials.discount.type ===
                                            DiscountType.RELATIVE
                                                ? ' %'
                                                : ''}
                                        </Box>
                                    </Typography>
                                )}
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                >
                                    {order.financials.total ===
                                    order.financials.subtotal
                                        ? 'Total'
                                        : 'Total after discount'}{' '}
                                    <Box
                                        component="span"
                                        className="black-text total"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                    >
                                        {formatMoney(order.financials.total)}
                                    </Box>
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                </Box>
                <Box className="bookings-summary" sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ color: '#000', pb: 1 }}>
                        Bookings Summary
                    </Typography>
                    {order.bookings
                        .filter(
                            booking =>
                                booking.status !== BookingStatus.CANCELLED
                        )
                        .map(booking => (
                            <>
                                <Box
                                    key={booking.id}
                                    className="booking"
                                    sx={{
                                        borderTop: '2px solid #000',
                                        paddingT: 1,
                                        paddingY: 3,
                                        marginBottom: booking.notes?.length
                                            ? 0.5
                                            : 2,
                                        pb: 0.5,
                                        display: 'grid',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        className="booking-header"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                    >
                                        <Box
                                            component="span"
                                            className="product-name"
                                        >
                                            {booking.product.name}
                                        </Box>
                                        <Box component="span"> • </Box>
                                        <Box
                                            component="span"
                                            className="product-type"
                                        >
                                            {booking.product.type ===
                                            ProductType.TOUR
                                                ? `${
                                                      booking.shared
                                                          ? 'Group'
                                                          : 'Private'
                                                  } `
                                                : ''}
                                            {t(booking.product.type, {
                                                ns: 'product',
                                            })}
                                        </Box>
                                        <Box component="span"> • </Box>
                                        <Box
                                            component="span"
                                            className="start-date"
                                        >
                                            {format(
                                                booking.date.start,
                                                'd MMM yyyy, HH:mm'
                                            )}
                                        </Box>{' '}
                                        {!!booking.date.end && (
                                            <>
                                                {' '}
                                                –{' '}
                                                <Box
                                                    component="span"
                                                    className="end-date"
                                                >
                                                    {format(
                                                        booking.date.end,
                                                        startOfDay(
                                                            booking.date.start
                                                        ).getTime() ===
                                                            startOfDay(
                                                                booking.date.end
                                                            ).getTime()
                                                            ? 'HH:mm'
                                                            : 'd MMM yyyy, HH:mm'
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                        <Box component="span"> • </Box>
                                        <Box
                                            component="span"
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                // backgroundColor:
                                                //     '#F00 !important',
                                                // color: '#FFF',
                                                // fontWeight: 600,
                                                // px: 0.75,
                                                // py: 0.5,
                                                // borderRadius: '10px',
                                            }}
                                        >
                                            {booking.status ===
                                            BookingStatus.DRAFT
                                                ? '>>>> DRAFT <<<<'
                                                : booking.status ===
                                                  BookingStatus.HOLD
                                                ? '>>>> HOLD <<<<'
                                                : '>>>> CONFIRMED <<<<'}
                                        </Box>
                                    </Typography>
                                    <Stack justifySelf="flex-start">
                                        <Box
                                            sx={{
                                                mt: 0.5,
                                                ml: 2,
                                                pb: 0.75,
                                                borderBottom: '1px solid #333',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                className="booking-details gray-text"
                                            >
                                                Booked on{' '}
                                                <Box
                                                    component="span"
                                                    sx={
                                                        {
                                                            // fontWeight: 700,
                                                            // color: '#000',
                                                        }
                                                    }
                                                    className="black-text booked-at"
                                                >
                                                    {format(
                                                        booking.createdAt,
                                                        'd MMM yyyy'
                                                    )}
                                                </Box>{' '}
                                                by{' '}
                                                <Box
                                                    component="span"
                                                    sx={
                                                        {
                                                            // fontWeight: 700,
                                                            // color: '#000',
                                                        }
                                                    }
                                                    className="black-text booked-by"
                                                >
                                                    {booking.createdBy}
                                                </Box>
                                                {booking.source.type ===
                                                    SourceType.DIRECT &&
                                                    sources[
                                                        booking.source.name
                                                    ] && (
                                                        <>
                                                            {' '}
                                                            via{' '}
                                                            <Box
                                                                component="span"
                                                                sx={
                                                                    {
                                                                        // fontWeight: 700,
                                                                        // color: '#000',
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    sources[
                                                                        booking
                                                                            .source
                                                                            .name
                                                                    ]
                                                                }
                                                            </Box>
                                                        </>
                                                    )}
                                            </Typography>
                                            {booking.source.type ===
                                                SourceType.REFERRAL && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{ mt: 0.5 }}
                                                    className="booking-details gray-text"
                                                >
                                                    Referral by{' '}
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {booking.source.name}
                                                    </Box>
                                                </Typography>
                                            )}
                                            <Typography
                                                variant="body2"
                                                sx={{ mt: 0.5 }}
                                                className="booking-details gray-text"
                                            >
                                                PAX{' '}
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: '#000',
                                                    }}
                                                    className="black-text pax"
                                                >
                                                    {formatPaxValue(
                                                        booking.participants.pax
                                                    )}
                                                </Box>
                                                {booking.participants
                                                    .travelers && (
                                                    <>
                                                        <Separator />
                                                        Travelers{' '}
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                fontWeight: 700,
                                                                color: '#000',
                                                            }}
                                                            className="black-text travelers"
                                                        >
                                                            {formatTravelersValue(
                                                                booking
                                                                    .participants
                                                                    .travelers
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                ml: 2,
                                                pt: 0.5,
                                                pb: 0.75,
                                                borderBottom: '1px solid #333',
                                            }}
                                        >
                                            <PriceValue
                                                title="Main product price"
                                                pricing={
                                                    booking.product.pricing
                                                }
                                                pax={booking.participants.pax}
                                            />
                                            {booking.product.options?.map(
                                                option =>
                                                    option.pricing && (
                                                        <PriceValue
                                                            title={`${option.name} price`}
                                                            pricing={
                                                                option.pricing
                                                            }
                                                            pax={option.pax}
                                                        />
                                                    )
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                ml: 2,
                                                pt: 0.5,
                                                pb: 0.75,
                                                borderBottom: '1px solid #333',
                                            }}
                                        >
                                            {booking.financials.total !==
                                                booking.financials.subtotal &&
                                                booking.product.pricing
                                                    .discount && (
                                                    <Typography variant="body1">
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            Total before
                                                            discount{' '}
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    color: '#000',
                                                                }}
                                                                className="black-text total-before-discount"
                                                            >
                                                                {formatMoney(
                                                                    booking
                                                                        .financials
                                                                        .subtotal
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Separator />
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            Discount{' '}
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    color: '#000',
                                                                }}
                                                                className="black-text discount"
                                                            >
                                                                {formatMoney(
                                                                    booking
                                                                        .product
                                                                        .pricing
                                                                        .discount
                                                                        .amount
                                                                )}
                                                                {booking.product
                                                                    .pricing
                                                                    .discount
                                                                    .type ===
                                                                DiscountType.RELATIVE
                                                                    ? ' %'
                                                                    : ''}
                                                            </Box>
                                                        </Box>
                                                    </Typography>
                                                )}

                                            <Typography variant="body1">
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {booking.financials
                                                        .total ===
                                                    booking.financials.subtotal
                                                        ? 'Total'
                                                        : 'Total after discount'}{' '}
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: '#000',
                                                        }}
                                                        className="black-text total-after-discount"
                                                    >
                                                        {formatMoney(
                                                            booking.financials
                                                                .total
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Separator />
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    To pay{' '}
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: '#000',
                                                        }}
                                                        className="black-text total-to-pay"
                                                    >
                                                        {formatMoney(
                                                            booking.financials
                                                                .debt
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                ml: 2,
                                                pt: 0.5,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                className="booking-header"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: '#000',
                                                }}
                                            >
                                                Travel Details
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="booking-header"
                                                sx={{ ml: 2 }}
                                            >
                                                Starts at{' '}
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: '#000',
                                                    }}
                                                    className="black-text"
                                                >
                                                    {format(
                                                        booking.date.start,
                                                        'd MMM yyyy, HH:mm'
                                                    )}
                                                </Box>
                                            </Typography>
                                            {!!getUniqPickups(booking)
                                                .length && (
                                                <>
                                                    <Typography
                                                        variant="body1"
                                                        className="booking-header"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: '#000',
                                                            ml: 2,
                                                        }}
                                                    >
                                                        Pick-ups
                                                    </Typography>
                                                    {getUniqPickups(
                                                        booking
                                                    ).map(pickUp => (
                                                        <Box sx={{ ml: 2 }}>
                                                            <Box component="span">
                                                                {' '}
                                                                •{' '}
                                                            </Box>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    // color: '#000',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                {pickUp.time} at{' '}
                                                                {
                                                                    pickUp.location
                                                                }
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </>
                                            )}
                                        </Box>
                                    </Stack>
                                </Box>

                                {!!booking.notes?.length && (
                                    <>
                                        <Typography
                                            variant="body1"
                                            className="booking-header"
                                            sx={{
                                                ml: 2,
                                                fontWeight: 700,
                                                color: '#000',
                                            }}
                                        >
                                            Notes
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ ml: 2, mb: 2 }}
                                        >
                                            {booking.notes.map(note => (
                                                <Box>
                                                    <Box component="span">
                                                        {' '}
                                                        •{' '}
                                                    </Box>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            // color: '#000',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {note.content}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Typography>
                                    </>
                                )}
                            </>
                        ))}
                </Box>

                {appendix && (
                    <Box
                        className="pagebreak"
                        dangerouslySetInnerHTML={{ __html: appendix }}
                    />
                )}
            </Box>
        </Box>
    );
};
