import {
    CreateCustomerReqDto,
    GetBookingsResItem0ProductCustomersItemDto,
} from '@travelity/api/src/requests';
import {
    BookingParticipants,
    convertFullNameToNameDto,
    convertNameDtoToFullName,
    Country,
    Customer,
    Nationality,
} from '@travelity/api';
import { format } from 'date-fns';
import {
    IBookingParticipantsForm,
    TravelerForm,
} from './booking-participants.types';

export const stringToDate = (str: string): Date => {
    const [d, m, y] = str.split('.');
    if (!d || !m || !y) return new Date();

    return new Date(parseInt(y, 10), parseInt(m, 10), parseInt(d, 10));
};

export const customerFormToCustomer = (
    customer: Customer
): Omit<CreateCustomerReqDto, 'access'> => ({
    name: convertFullNameToNameDto(customer.fullName),
    contact_details: {
        emails: customer.emails?.map(({ value }) => value),
        phone_numbers: customer.numbers
            ?.map(({ value }) => value)
            .map(number => ({
                calling_code: parseInt(number.split(' ')[0], 10),
                number: parseInt(number.split(' ')[1], 10),
            })),
    },
    birth_details:
        customer.birthDate || customer.nationality || customer.birthPlace
            ? {
                  nationality:
                      (customer.nationality?.toLowerCase() as Nationality) ||
                      undefined,
                  date: customer.birthDate
                      ? Math.round(
                            stringToDate(customer.birthDate).getTime() / 1000
                        )
                      : undefined,
                  place: customer.birthPlace
                      ? {
                            name: customer.birthPlace,
                        }
                      : undefined,
              }
            : undefined,
    passport:
        customer.passportN || customer.citizenship
            ? {
                  number: customer.passportN || undefined,
                  citizenship:
                      (customer.citizenship?.toLowerCase() as Country) ||
                      undefined,
                  authority: customer.issuedBy,
                  issued_at: customer.issuedAt
                      ? Math.round(
                            stringToDate(customer.issuedAt).getTime() / 1000
                        )
                      : undefined,
                  expires_at: customer.expiresAt
                      ? Math.round(
                            stringToDate(customer.expiresAt).getTime() / 1000
                        )
                      : undefined,
              }
            : undefined,
    languages: customer.languages || [],
});

export const travelerToTravelerForm = (
    traveler: Omit<GetBookingsResItem0ProductCustomersItemDto, 'booking_id'>
): TravelerForm => ({
    travelerId: traveler.id,
    customerId: traveler.reference.id,
    fullName: convertNameDtoToFullName(traveler.reference.name),
    emails:
        traveler.reference.contact_details?.emails?.map(value => ({ value })) ||
        [],
    numbers:
        traveler.reference.contact_details?.phone_numbers?.map(
            ({ number, calling_code }) => ({
                value: `${calling_code} ${number}`,
            })
        ) || [],
    birthDate: traveler.reference.birth_details?.date
        ? format(
              new Date(traveler.reference.birth_details.date * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    birthPlace: traveler.reference.birth_details?.place?.name,
    nationality: traveler.reference.birth_details?.nationality,
    passportN: traveler.reference.passport?.number,
    issuedBy: traveler.reference.passport?.authority,
    issuedAt: traveler.reference.passport?.issued_at
        ? format(
              new Date(traveler.reference.passport.issued_at * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    expiresAt: traveler.reference.passport?.expires_at
        ? format(
              new Date(traveler.reference.passport.expires_at * 1000),
              'dd.MM.yyyy'
          )
        : undefined,
    pickUp: traveler.pickup
        ? {
              id: traveler.pickup.id,
              type: traveler.pickup.type,
              name: traveler.pickup.location.name,
              time: traveler.pickup.schedule.date
                  ? new Date(traveler.pickup.schedule.date.start * 1000)
                  : new Date(),
          }
        : undefined,
    citizenship: traveler.reference.passport?.citizenship,
    guestCount: traveler.plus_x,
    type: 'traveler',
    languages: traveler.reference.languages || [],
    attachments:
        traveler.reference.attachments?.map(attachment => ({
            name: attachment.name,
            contentType: attachment.content_type,
            url: attachment.url || '',
            uuid: attachment.id,
        })) || [],
    attendance: traveler.attendance?.type,
});

export const participantsToParticipantsForm = (
    participants: BookingParticipants
): IBookingParticipantsForm => ({
    pax: participants.pax,
    customer: participants.customer,
    travelers: participants.travelers || [],
});
