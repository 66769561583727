/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateBookingSourceBookingsReq1Dto = {
    /**
     * type of the source
     */
    type: UpdateBookingSourceBookingsReq1Dto.type;
    /**
     * Person's name, separated into components.
     */
    member_id: string;
};

export namespace UpdateBookingSourceBookingsReq1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
