import React, { useMemo } from 'react';
import {
    Box,
    ClickAwayListener,
    Divider,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Heading } from '@travelity/ui';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { RouteStop, TravelerPickUp } from '@travelity/api';
import { RouteItemType } from '@travelity/api/src/requests';
import { RouteEditable } from '../route-editable';
import { Popper } from '../popper';
import Transitions from '../transitions/transitions';

export interface SelectPickupPointProps {
    stops: RouteStop[];
    selected?: TravelerPickUp;
    setSelected: (v?: TravelerPickUp) => void;
    editing: boolean;
    disabled?: boolean;
}

const orderOfStopTypes = [
    RouteItemType.STARTPOINT,
    RouteItemType.STOP,
    RouteItemType.ENDPOINT,
];

export const SelectPickupPoint: React.FC<SelectPickupPointProps> = props => {
    const { stops, editing, selected, disabled, setSelected } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const localStops = useMemo(
        () =>
            (!selected || selected?.id
                ? [...stops]
                : [...stops, { ...selected, pinned: false }]
            ).sort((a, b) => {
                if (a.time.getTime() === b.time.getTime()) {
                    return (
                        orderOfStopTypes.indexOf(a.type) -
                        orderOfStopTypes.indexOf(b.type)
                    );
                }
                return a.time.getTime() - b.time.getTime();
            }),
        [stops, selected]
    );

    return (
        <Stack
            sx={{ width: 1, opacity: disabled ? 0.7 : 1 }}
            direction="row"
            gap={1}
        >
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{
                    flexGrow: 2,
                    alignItems: 'flex-start',
                    padding: '10px 12px',
                    borderRadius: '12px',
                    mr: 5,
                    border: '1px solid rgba(30, 41, 120, 0.10)',
                    background: '#FFF',
                    boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                    ...(editing
                        ? {
                              cursor: 'pointer',
                          }
                        : {}),
                    ...(disabled
                        ? {
                              pointerEvents: 'none',
                          }
                        : {}),
                }}
                onClick={editing ? handleClick : undefined}
            >
                <FmdGoodIcon sx={{ fontSize: '12px', color: '#2B395B' }} />
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                        color: '#2B395B',
                        fontSize: '14px',
                        lineHeight: '100%',
                    }}
                >
                    {selected?.name ||
                        (editing
                            ? 'Click to select Pickup Information'
                            : 'No Pickup Information')}
                </Typography>
            </Stack>

            <Popper
                placement="bottom-start"
                role={undefined}
                transition
                sx={{ zIndex: 10000 }}
                anchorEl={anchorEl}
                open={open}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: 'right',
                // }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                px: 0,
                                borderRadius: '12px',
                                border: '1px solid #DFE1ED',
                                background: '#FFF',
                                opacity: 1,
                                boxShadow: '0px 0px 16px 0px #B2B9CD',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    <Heading
                                        sx={{
                                            my: 2,
                                            mx: 2,
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            lineHeight: '100%',
                                        }}
                                        alpha={0.5}
                                    >
                                        Select pickup point
                                    </Heading>
                                    <Divider />

                                    <Box
                                        component={PerfectScrollbar}
                                        sx={{ maxHeight: '250px' }}
                                        option={{
                                            suppressScrollX: true,
                                        }}
                                    >
                                        <RouteEditable
                                            stops={localStops}
                                            selected={selected}
                                            setSelected={setSelected}
                                            timelineProps={{
                                                sx: {
                                                    mb: 0,
                                                    mt: 0.5,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Stack>
    );
};
